import Common_axios from "./CommonAxios";

let baseURL = Common_axios.defaults.baseURL;


//verify is goqii user or not 
export const verify_users_access = (memberShipNo) =>{
    let params = {'memberShipNo': memberShipNo}
    var dataPass = Common_axios.post(baseURL + 'pwa/verify_users_access', params);
    return dataPass;
}

export const steps_log_data = (uid,sdate,edate,type) => {
    
    let params = {'uid':uid,'startDate':sdate,'endDate':edate,'type':type};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_steps_log_data',params);
    return dataPass;
};

export const water_log_data = (uid,sdate,edate,type) => {
    let params = {'uid':uid,'startDate':sdate,'endDate':edate,'type':type};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_water_log_data',params);
    return dataPass;
};

export const sleep_log_data = (uid,sdate,edate,type) => {
    
    let params = {'uid':uid,'startDate':sdate,'endDate':edate,'type':type};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_sleep_log_data',params);
    return dataPass;
};

export const activity_list_get_data = (uid) => {
    let params = {'uid':uid};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_activity_list',params);
    return dataPass;
};
export const recommended_blog_list = (uid) => {
    let params = {'uid':uid};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_recommended_blog_list',params);
    return dataPass;
};
export const upcoming_call_list = (uid) => {
    let params = {'uid':uid};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_upcoming_call_list',params);
    return dataPass;
};

export const care_navigator_list = (uid,cnId) => {
    let params = {'uid':uid,'cnId':cnId};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_care_navigator_list',params);
    return dataPass;
};

export const submit_user_carenavigator_feedback = (uid,cnId,feedback,numberStars) => {
    let params = {'uid':uid,'cnId':cnId,'feedback':feedback,'numberStars':numberStars};
    var dataPass = Common_axios.post(baseURL + 'pwa/submit_user_carenavigator_feedback',params);
    return dataPass;
};

export const homepage_hra = (uid) => {
    let params = {'uid':uid};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_homepage_hra',params);
    return dataPass;
};

export const homepage_recommended_video = (uid) => {
    let params = {'uid':uid};
    var dataPass = Common_axios.post(baseURL + 'pwa/fetch_homepage_recommended_video',params);
    return dataPass;
};

export const reverify_users_access = () => {
   // let params = {'uid':uid};
    var dataPass = Common_axios.get(baseURL + 'pwa/reverify_users_access');
    return dataPass;
};









export const heartrate_log_data = (params,sdate,edate,type) => {
    console.log("heart rate", type)
    var dataPass = Common_axios.post(baseURL + 'heartrate_log_data?startDate='+sdate.formattedStartDate+'&endDate='+edate.formattedEndDate+'&type='+type,params);
    return dataPass;
};

export const spo2_log_data = (params,sdate,edate,type) => {
    console.log("spo2", type)
    var dataPass = Common_axios.post(baseURL + 'spo2_log_data?startDate='+sdate.formattedStartDate+'&endDate='+edate.formattedEndDate+'&type='+type,params);
    return dataPass;
};



export const food_log_post_data = (params) => {
    console.log("Food log post", params)  
    var dataPass = Common_axios.post(baseURL + 'food_log_post_data',params);
    return dataPass;
};
export const activity_log_post_data = (params) => {
    console.log("activity log post", params)
    let dataPass = Common_axios.post(baseURL + 'activity_log_post_data',params);
    return dataPass;
};

export const water_log_post_data = (params) => {
    console.log("water log post", params)
    let dataPass = Common_axios.post(baseURL + 'water_log_post_data',params);
    return dataPass;
};

export const sleep_log_post_data = (params) => {
    console.log("sleep log post", params)
    let dataPass = Common_axios.post(baseURL + 'sleep_log_post_data',params);
    return dataPass;
};

export const weight_list_get_user_data = (selectedDate) => {
    console.log("weight_list_get_user_data")
    let dataPass = Common_axios.post(baseURL + 'weight_list_get_user_data?date='+selectedDate);
    return dataPass;
};

export const weight_log_post_data = (params) => {
    console.log("weight log post", params)
    let dataPass = Common_axios.post(baseURL + 'weight_log_post_data',params);
    return dataPass;
};



export const water_list_get_user_data = (selectedDate) => {
    console.log("water_list_get_user_data")
    let dataPass = Common_axios.post(baseURL + 'water_list_get_user_data?date='+selectedDate);
    return dataPass;
};

export const sleep_list_get_user_data = (selectedDate) => {
    console.log("sleep_list_get_user_data")
    let dataPass = Common_axios.post(baseURL + 'sleep_list_get_user_data?date='+selectedDate);
    return dataPass;
};

export const activity_list_get_user_data = (selectedDate) => {
    console.log("activity_list_get_user_data")
    let dataPass = Common_axios.post(baseURL + 'activity_list_get_user_data?date='+selectedDate);
    return dataPass;
};

export const unit_list_get_user_data = () => {
    console.log("unit_list_get_user_data")
    let dataPass = Common_axios.post(baseURL + 'unit_list_get_user_data');
    return dataPass;
};

export const user_delete_log_data = (params) => {
    console.log(params);
    console.log("sleep_delete_user_data")
    let dataPass = Common_axios.post(baseURL + 'user_delete_log_data?data='+params);
    return dataPass;
};

export const fetchVerifyData = async () => {
        let response;
    try {
        console.log("verify data");
        response = await verify_users_access();
        console.log("response verify data", response);
        if(response.data.data.code !== 200){
          //navigate('/error');
        }
    } catch (error) {
        console.error(error);
    }
    return response;
};











