import React, { useState, useEffect } from 'react';
import './ActivityLogEntry.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import NotificationToast from '../component/NotificationToast';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { activity_log_post_data, activity_list_get_data, activity_list_get_user_data,user_delete_log_data } from "../services/Services";

function ActivityLogEntry(props) {
    console.log("time",props)
  const uid = localStorage.getItem("uid");
  const [activeCategory, setActiveCategory] = useState('');
  const [inputDurationHours, setInputDurationHours] = useState(0);
  const [inputDurationMinutes, setInputDurationMinutes] = useState(0);
  const [inputValue, setInputValue] = useState();
  const [inputTimeValue, setinputTimeValue] = useState('');
  const [intensity, setIntensity] = useState('easy');
  const [listActivity, setListActivity] = useState([]);
  const [listActivityLog, setListActivityLog] = useState([]);
  const [listActivityLogCount, setListActivityLogCount] = useState(0);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState('');
  const [toastKey, setToastKey] = useState(0); // State variable for the key
  //const [btnColor, setBtnColor] = useState('btn next-btn btn-secondary');
  const [isLoading, setIsLoading] = useState(false); // State variable for loader
  const [disable, setDisable] = useState(true);


  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    //setBtnColor("btn next-btn btn-success");
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    if(event.target.name === "currentInputTime"){
      setInputValue(event.target.value);
    }else if(event.target.name === "inputDurationHours"){
        let { value } = event.target;
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');
       if (/^\d{0,2}$/.test(value) && event.target.value >=0 && event.target.value < 24) {
          setInputDurationHours(value);
          setDisable(true);
        }else{
          setErrors("Enter valid time duration");
          setDisable(false);
          setToastKey(prevKey => prevKey + 1);
        }
    }else if(event.target.name === "inputDurationMinutes"){
      let { value } = event.target;
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');
        if (/^\d{0,2}$/.test(value) && event.target.value >=0 && event.target.value < 60) {
          setInputDurationMinutes(value);
          setDisable(true);
        }else{
          setErrors("Enter valid time duration");
          setDisable(false);
          setToastKey(prevKey => prevKey + 1);
        }
        
    }else if(event.target.name === "intensityInput"){
        setIntensity(event.target.value);
    }    
  };

  const submitActivityDataLog = async ()=>{
    setDisable(false);
    console.log("final Activity submit",activeCategory,inputValue,inputDurationHours,inputDurationMinutes,intensity);
    setIsLoading(true); // Show loader
    setErrors("");
    if (!activeCategory) {
        setIsLoading(false); // Hide loader
        setErrors("Please select one activity.");
        setDisable(true);
      }else if(inputDurationHours === 0 && inputDurationMinutes === 0) {
        setIsLoading(false); // Hide loader
        setErrors("Please enter duration value.");
        setDisable(true);
      }else{
            let logData = {
            'logDate':props.calendarData,
            'logName':activeCategory,
            'logTime':inputValue,
            'logActivityHours':inputDurationHours,
            'inputDurationMinutes':inputDurationMinutes,
            'intensity':intensity
            }
            try {
              const response = await activity_log_post_data(logData);
              if(response.data.code === 200){
                fetchActivityLogData();
                setSuccess(response.data.data.message);
                setDisable(true)
              }else{
                setErrors(response.data.data.message);
                setDisable(true);
              }
            } catch (error) {
              console.error(error);
              setErrors("An error occurred while submitting activity data.");
            }
            setIsLoading(false); // Hide loader

            // console.log("activityData1",logData);
            // const response = await activity_log_post_data(logData);
            // console.log("response food data post", response.data.data.message);
            // setSuccess(response.data.data.message);
            //sendDataToAndroid();
    }
    setToastKey(prevKey => prevKey + 1);
  }

  /*const sendDataToAndroid = () => {
    var dataToSend = {
      type: "close_screen",
      message: "Log Successfully"
    };
    window.Android.sendDataToAndroid(JSON.stringify(dataToSend));
  }*/

  

  const fetchActivityListData = async () => {
    try {
        const response = await activity_list_get_data(uid);
        console.log("response activity list", response.data.data);
        setListActivity(response.data.data);
      } catch (error) {
        console.error(error);
        setErrors("An error occurred while fetching activity data.");
      }
};


const userDeleteLog = async(deleteLogId,type) =>{
    let response;
  try {
      var data = {
        type: type,
        deleteLogId: deleteLogId
      };
      console.log("user delete Log",deleteLogId);
      response = await user_delete_log_data(JSON.stringify(data));
      if(response.data.code === 200){
        fetchActivityLogData();
        setSuccess(response.data.data.message)
      }else{
        setErrors("An error occurred while deleting activity log data.");
      }
    } catch (error) {
        console.error(error);
        setErrors("An error occurred while deleting activity log data.");
    }
    return response;
  }

  const fetchActivityLogData = async () => {
    try {
        const response = await activity_list_get_user_data(props.calendarData);
        console.log("response fetchActivityLogData", response.data.data);
        setListActivityLog(response.data.data);
        console.log("activity log count",response.data.data.length)
        setListActivityLogCount(response.data.data.length);
    } catch (error) {
        console.error(error);
        setErrors("An error occurred while fetching activity log data.");
    }
  };

useEffect(() => {

    fetchActivityListData();

   // fetchActivityLogData();

  },[success,errors,props.calendarData]);
  

  return (
    <>
      <section className="ActivityLogEntry">
        <div className="floating-loader" style={{ display: isLoading ? 'block' : 'none' }}>
          <div className="loader"></div>
          Loading...
        </div>
        <div className="container">
            <div className='row align-items-center'>
                <div className='col'>
                    <h1 className='activity-main-title'>Log New Activity</h1>
                </div>
                {/* <div className='col-auto'>
                    <button type="button" className="btn add-plus"><i className='bx bx-plus'></i></button>
                </div> */}
            </div>
          <div className='row'>
            <div className='col-12 mt-4'>
                <label className="form-label">Activity</label>
                                <Swiper
                                    slidesPerView={'auto'}
                                    spaceBetween={0}
                                    pagination={{
                                    clickable: false,
                                    }}
                                    className="myHabitMenu"
                                >
                                    {/* <SwiperSlide>
                                        <div className={`col text-center ${activeCategory === 'yoga' ? 'active' : ''}`} onClick={() => handleCategoryClick('yoga')}>
                                            <img src={require(`../images/${activeCategory === 'yoga' ? 'yoga-a.png' : 'yoga.png'}`)} className="img-fluid activitylog-icon" alt="" />
                                            <h4 className={`activitylog-title ${activeCategory === 'yoga' ? 'active' : ''}`}>Yoga</h4>
                                        </div>
                                    </SwiperSlide> */}
                                    {listActivity.map(activity => (
                                    <SwiperSlide key={activity.activityId}>
                                        <div className={`col text-center d-flex flex-wrap justify-content-center ${activeCategory === activity.activityId ? 'active' : ''}`} onClick={() => handleCategoryClick(activity.activityId)}>
                                        <img src={activeCategory === activity.activityId ? activity.activeImgUrl : activity.imgUrl} className="img-fluid activitylog-icon" alt="" />
                                        <h4 className={`activitylog-title ${activeCategory === activity.activityId ? 'active' : ''}`}>{activity.activityName}</h4>
                                        </div>
                                    </SwiperSlide>
                                    ))}

                                </Swiper>
            </div>

          </div>

        <div className='row mt-5'>
            
            <div className='col-5 pe-0'>
                <div className=' input-icons'>
                <label htmlFor='inputTime' className="form-label">Start Time</label>
                <input
                    autoComplete="off"
                    name="currentInputTime"
                    disable={true}
                    type="time"
                    defaultValue="10:12"
                    className="form-control"
                    id="inputTime"
                    onChange={handleInputChange}
                    
                />
                <i className="bi bi-clock" htmlFor='inputTime'></i>
                </div>
            </div>
            <div className='col-3 px-0'>
                <div className='input-icons'>
                <label htmlFor='inputDurationHours' className="form-label">Duration</label>
                <div className='row'>
                    <div className='col-6 pe-0'>
                        <input
                        autoComplete="off"
                        name="inputDurationHours"
                        disable={true}
                        type="number"
                        className="form-control duration-input"
                        id="inputDurationHours"
                        placeholder='00'
                        onChange={handleInputChange}
                        
                    />
                    </div>
                    <div className='col-6 ps-0'>
                        <input
                        autoComplete="off"
                        name="inputDurationMinutes"
                        disable={true}
                        type="number"
                        className="form-control duration-input"
                        id="inputDurationMinutes"
                        placeholder='00'
                        onChange={handleInputChange}
                        maxLength="2"
                    />
                    </div>
                </div>
                </div>
            </div>
            <div className='col-4'>
                <label htmlFor='Intensity' className="form-label">Intensity</label>
                <select className="form-select intensity-input" aria-label="Default select example" name="intensityInput" onChange={handleInputChange} value={intensity}>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                </select>
            </div>
        </div>
        {/* {errors && <NotificationToast message={errors} key={toastKey} type="error"/>} */}
        {success && <NotificationToast message={success} key={toastKey} type="success" />}
        <div className='row mt-4'>
            <div className='col-6'>
              <button type="button" className="btn btn-success back-btn">Cancel</button>
            </div>
            <div className='col-6'>
              <button type="button" className="btn next-btn btn-success" disabled={!disable} onClick={submitActivityDataLog}>Log</button>
            </div>
            <div className='col-12 mt-3'>
            <hr/>
            </div>
        </div>

        <div className='row'>
            <div className='col-12'>
                <h2 className='activit-detail-title'>
                    Logged Activities
                </h2>
            </div>
            <div className='col-12 px-4'>
            {listActivityLogCount === 0 ? (
                <div className="text-center">No activity found.</div>
              ) : (
                listActivityLog.map(activity => (
            <div className='row py-3 border-bottom' key={activity.activityLogId}>
                 <div className='col-auto'>
                    <img src={activity.imgUrl} className="activitylog-icon1" alt="" />
                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label'>{activity.activityTittle}</h3>
                    <h3 className='form-label'>{activity.caloriesBurnt}</h3>
                 </div>
                 <div className='col-auto text-end'>
                    <h3 className='form-label'>{activity.activityTime}</h3>
                    <h3 className='form-label' onClick={(e) => userDeleteLog(activity.activityLogId,'activity')}><i class='bx bx-trash'></i></h3>
                 </div>
            </div>
                ))
              )}
            {/* <div className='row py-3 border-bottom'>
                 <div className='col-auto'>
                    <img src={require(`../images/yoga.png`)} className="activitylog-icon1" alt="" />
                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label'>Thr of Easy Weights</h3>
                    <h3 className='form-label'>177 calories burnt</h3>
                 </div>
                 <div className='col-auto text-end'>
                    <h3 className='form-label'>3 hrs ago</h3>
                 </div>
            </div> */}
            </div>
            
        </div>
          
        </div>
      </section>
    </>
  );
}

export default ActivityLogEntry;
