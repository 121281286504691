import React from 'react';
import { motion } from 'framer-motion';

function HRAQ2({ updateHraData, hraData }) {
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    updateHraData(name, value);  // Update the parent component's state
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      className=''
    >
      <div className='row'>
        <div className="col-12 mb-3">
          <h1 className='hra-title'>What is your goal within the next year?</h1>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input 
              type="radio" 
              className="form-check-input form-radio-input-ui" 
              name="goal" 
              id="hraRadios1" 
              value="Loose Weight" 
              
              onChange={handleInputChange}
            />
            <label htmlFor='hraRadios1' className="form-check-label form-label-input mt-[6px]">Loose Weight</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input 
              type="radio" 
              className="form-check-input form-radio-input-ui" 
              name="goal" 
              id="hraRadios2" 
              value="Gain Weight" 
              onChange={handleInputChange}
            />
            <label htmlFor='hraRadios2' className="form-check-label form-label-input mt-[6px]">Gain Weight</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input 
              type="radio" 
              className="form-check-input form-radio-input-ui" 
              name="goal" 
              id="hraRadios3" 
              value="Maintain Weight in a healthier way" 
              onChange={handleInputChange}
            />
            <label htmlFor='hraRadios3' className="form-check-label form-label-input mt-[6px]">Maintain Weight in a healthier way</label>
          </div>
        </div>
      </div>

      <div className='row mt-3'>
        <div className="col-12 mb-3">
          <h1 className='hra-title'>Have you used any weight loss product (pills or injection) or have done any weight loss surgery before?</h1>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input 
              type="radio" 
              className="form-check-input form-radio-input-ui" 
              name="goal" 
              id="hraRadiosSub1" 
              value="Yes" 
              
              onChange={handleInputChange}
            />
            <label htmlFor='hraRadiosSub1' className="form-check-label form-label-input mt-[6px]">Yes</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input 
              type="radio" 
              className="form-check-input form-radio-input-ui" 
              name="goal" 
              id="hraRadiosSub2" 
              value="YES, I am thinking and would like to get advice" 
              onChange={handleInputChange}
            />
            <label htmlFor='hraRadiosSub2' className="form-check-label form-label-input mt-[6px]">YES, I am thinking and would like to get advice</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input 
              type="radio" 
              className="form-check-input form-radio-input-ui" 
              name="weightLossHistory" 
              id="hraRadiosSub3" 
              value="NO, I am not interested I would like to reach my goal with healthy diet and exercise" 
              onChange={handleInputChange}
            />
            <label htmlFor='hraRadiosSub3' className="form-check-label form-label-input mt-[6px]">NO, I am not interested I would like to reach my goal with healthy diet and exercise</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label htmlFor='hraSub-1' className="form-label form-label-input">Specify</label>
          <textarea 
            className="form-control control-input-ui" 
            id="weightSpecify" 
            placeholder="Specify" 
            name="weightSpecify"
            rows="3"
            value={hraData.weightSpecify}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>
    </motion.div>
  );
}

export default HRAQ2;
