import React from 'react';
import { motion } from "framer-motion";
import './CallWithYourCareNavigator.css';
import HRACard from './component/HRACard';
import UpcomingCall from './component/UpcomingCall';
import { useLocation } from 'react-router-dom';

const CallWithYourCareNavigator = () => {
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const uidParam = searchParams.get('uid');


  return (
    <>
    <motion.section 
        className="CallWithYourCareNavigator mt-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >

        <div className='container'>
          <div class="row">
              <div className='col-12 mb-3 px-1'>
                  <h1 className='maintitle'>Call With Your Care Navigator</h1>
              </div>
              {/* <div className='col-12 mb-3 px-1'>
                <motion.div
                    initial={{ opacity: 0, y: 20  }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    >
                     <HRACard/>   
                </motion.div>
              </div> */}
              <div className='col-12 mb-3 px-1'>
                <motion.div
                    initial={{ opacity: 0, y: 20  }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    >
                     <UpcomingCall dataUid={uidParam}/>
                </motion.div>
              </div>
          </div>
        </div>
        
    </motion.section>
    </>
  );
};

export default CallWithYourCareNavigator;