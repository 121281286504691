import React from 'react';
import './StatsWater.css';

const StatsWater = () => {
  return (
    <>
        <section className='StatsWater'>
                <div className='card bg-main-StatsWater-card'>
                    <div className='card-body p-2'>

                        <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsWater-sub-title'>Water Consumed</h2>
                            </div>
                            <div className='col-6'>
                                <h2 className='StatsWater-content-title'>1<span> L</span></h2>
                            </div>
                        </div>

                    </div>
                </div>
           
        </section>        
    </>
  );
};

export default StatsWater;