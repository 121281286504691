import React from 'react';
import { motion } from "framer-motion";
import PieChartWithProgress from '../component/PieChartWithProgress';

import './LogBoxes.css';
import PieChartWithProgressSleep from './PieChartWithProgressSleep';

function LogBoxes() {

    const openInNewTab = (url) => {
        const isMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        if (isMobile) {
          window.location.href = url;
        } else {
          const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null;
        }
    };

  return (
    <>
        <section className='LogBoxes mt-2'> 
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-4 px-2'>
                    <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        >
                       <div className='logs-bx'>
                            <div className='row align-items-center'>
                                <div className='col-auto pe-2'>
                                    <img src={require('../../../images/icon-steps-log.png')} className="logs-icon" alt="" />
                                </div>
                                <div className='col ps-0'>
                                    <h1 className='log-box-title'>Steps</h1>
                                </div>
                                <div className='col-12 mt-2'>
                                    <span onClick={() => openInNewTab('steps-detail')}>
                                        <h2 className='log-box-subtitle'>6,000</h2>
                                        <PieChartWithProgress totalPieValue = '4300' />
                                    </span>
                                        <br/>
                                    {/* <button type="button" class="btn btn-primary btn-sm btn-logs">Log</button> */}
                                </div>
                            </div>
                       </div>
                       </motion.div>
                    </div>

                    <div className='col-4 px-2'>
                    <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                        >
                       <div className='logs-bx'>
                            <div className='row align-items-center'>
                                <div className='col-auto pe-2'>
                                    <img src={require('../../../images/icon-water-log.png')} className="logs-icon" alt="" />
                                </div>
                                <div className='col ps-0'>
                                    <h1 className='log-box-title'>Water</h1>
                                </div>
                                <div className='col-12 mt-2'>
                                    <span onClick={() => openInNewTab('water-detail')}>
                                        <h2 className='log-box-subtitle'>2L</h2>
                                        <PieChartWithProgress totalPieValue = '1 L' />
                                    </span>
                                    <button onClick={() => openInNewTab('water-log')} type="button" class="btn btn-primary btn-sm btn-logs">Log</button>
                                </div>
                            </div>
                       </div>
                       </motion.div>
                    </div>

                    <div className='col-4 px-2'>
                    <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                        >
                       <div className='logs-bx'>
                            <div className='row align-items-center'>
                                <div className='col-auto pe-2'>
                                    <img src={require('../../../images/icon-sleep-log.png')} className="logs-icon" alt="" />
                                </div>
                                <div className='col ps-0'>
                                    <h1 className='log-box-title' >Sleep</h1>
                                </div>
                                <div className='col-12 mt-2'>
                                    <span onClick={() => openInNewTab('sleep-detail')}><h2 className='log-box-subtitle'>7 Hr</h2>
                                    <PieChartWithProgressSleep totalPieValue = '6 Hr'/>
                                    </span>
                                    <button onClick={() => openInNewTab('sleep-log')} type="button" class="btn btn-primary btn-sm btn-logs">Log</button>
                                </div>
                            </div>
                       </div>
                       </motion.div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default LogBoxes;
