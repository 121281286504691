import React from 'react';
import './HabitsProgress.css';

function HabitsProgress() {
  return (
    <>
        {/* <section className='HabitsProgress mt-4'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h1 className='HabitsProgress-title'>Habits Progress</h1>
                    </div>
                    <div className='col-12'>

                        <div className='habit-box mt-3'>
                            <div className='row'>
                                <div className='col-auto pe-2'>
                                    <img src={require('../../../images/icon-meditation-habit.png')} className="habit-icon" alt="" />
                                </div>
                                <div className='col ps-0'>
                                    <h1 className='habit-box-title mt-1'>Have Medicines</h1>
                                    <div className='habit-dates-c'>
                                        <div className='habit-day-box active'>
                                            S
                                        </div>
                                        <div className='habit-day-box active'>
                                            M
                                        </div>
                                        <div className='habit-day-box active'>
                                            T
                                        </div>
                                        <div className='habit-day-box '>
                                            W
                                        </div>
                                        <div className='habit-day-box '>
                                            T
                                        </div>
                                        <div className='habit-day-box '>
                                            F
                                        </div>
                                        <div className='habit-day-box '>
                                            S
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto text-center'>
                                    <h2 className='habit-box-subtitle mb-2'>Set Reminder</h2>
                                    <img src={require('../../../images/habit-tick.png')} className="habit-tick-icon" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='habit-box mt-3'>
                            <div className='row'>
                                <div className='col-auto pe-2'>
                                    <img src={require('../../../images/icon-steps-log.png')} className="habit-icon" alt="" />
                                </div>
                                <div className='col ps-0'>
                                    <h1 className='habit-box-title mt-1'>Take 6k Steps</h1>
                                    <div className='habit-dates-c'>
                                        <div className='habit-day-box active'>
                                            S
                                        </div>
                                        <div className='habit-day-box active'>
                                            M
                                        </div>
                                        <div className='habit-day-box active'>
                                            T
                                        </div>
                                        <div className='habit-day-box '>
                                            W
                                        </div>
                                        <div className='habit-day-box '>
                                            T
                                        </div>
                                        <div className='habit-day-box active'>
                                            F
                                        </div>
                                        <div className='habit-day-box '>
                                            S
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto text-center'>
                                    <h2 className='habit-box-subtitle mb-2'>Set Reminder</h2>
                                    <img src={require('../../../images/habit-tick-a.png')} className="habit-tick-icon" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='habit-box mt-3'>
                            <div className='row'>
                                <div className='col-auto pe-2'>
                                    <img src={require('../../../images/icon-food-habit.png')} className="habit-icon" alt="" />
                                </div>
                                <div className='col ps-0'>
                                    <h1 className='habit-box-title mt-1'>Eat Healthy</h1>
                                    <div className='habit-dates-c'>
                                        <div className='habit-day-box active'>
                                            S
                                        </div>
                                        <div className='habit-day-box active'>
                                            M
                                        </div>
                                        <div className='habit-day-box active'>
                                            T
                                        </div>
                                        <div className='habit-day-box '>
                                            W
                                        </div>
                                        <div className='habit-day-box '>
                                            T
                                        </div>
                                        <div className='habit-day-box '>
                                            F
                                        </div>
                                        <div className='habit-day-box '>
                                            S
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto text-center'>
                                    <h2 className='habit-box-subtitle mb-2'>Set Reminder</h2>
                                    <img src={require('../../../images/habit-tick.png')} className="habit-tick-icon" alt="" />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section> */}

    </>
  );
}

export default HabitsProgress;
