import axios from "axios";


var Common_axios = axios.create({});

Common_axios.defaults.baseURL = 'https://apiv6.goqii.com/';
       
Common_axios.interceptors.request.use(
    
  function(config) {
    const token = localStorage.getItem("authToken");
    const uid = localStorage.getItem("uid"); // Retrieve the UID from localStorage
    // Log the token and uid for debugging
    console.log("token:", token);
    console.log("uid:", uid);
    if (uid) {
      config.headers["uid"] = uid;
    }

    config.params = { ...config.params}

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);



Common_axios.interceptors.response.use(
    
    function (response) {
         console.log("succes",response);
        return response;
    },
    function (error) {
        console.log("error1234", error);
        // if (error.response.status == 404) {
        //     window.location.href = '/logout';
        // }
        // if (error.response.status == 401) {
        //     window.location.href = '/logout';
        // }
        // console.log(error.response.status);
    }
);


export default Common_axios;