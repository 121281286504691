import React from 'react';
import './JoinCommunity.css';

function JoinCommunity() {
  return (
    <>
        <section className='JoinCommunity mt-4 mb-4'>
            <div className='container'>
                <div className='row align-items-center mb-2'>
                <div className='col'>
                        <h1 className='Community-title'>Join Community</h1>
                    </div>
                    {/* <div className='col-auto'>
                        <button type="button" class="btn view-all">View All</button>
                    </div> */}
                </div>
                <div className='row align-items-center'>
                    
                    <div className='col-6 px-2'>
                        <div className='JoinCommunity-box'>
                            <img src="https://appcdn.goqii.com/storeimg/10902_1709277170.png" className="Community-img" alt="" />
                            <div className='Community-box-gredient text-center'>
                                <h2 className='Community-box-title mb-1'>Eat Healthy</h2>
                                <h2 className='Community-box-title mb-3'><span>Log 3 healthy foods daily</span></h2>
                                <button type="button" class="btn btn-primary btn-join">Join</button>
                            </div>
                        </div>
                    </div>

                    <div className='col-6 px-2'>
                        <div className='JoinCommunity-box'>
                            <img src="https://appcdn.goqii.com/storeimg/44980_1709277189.png" className="Community-img" alt="" />
                            <div className='Community-box-gredient text-center'>
                                <h2 className='Community-box-title mb-1'>Daily 6K Step</h2>
                                <h2 className='Community-box-title mb-3'><span>Take 6K Steps Daily</span></h2>
                                <button type="button" class="btn btn-primary btn-join">Join</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    </>
  );
}

export default JoinCommunity;
