import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WhatsIncluded.css';

const WhatsIncluded = () => {
    const navigate = useNavigate();
    const navigatePage =()=>{
        navigate("/select-your-care-navigator");
    }
  return (
    <>
        <section className='WhatsIncluded mt-2 mb-[40px]'>

            <div className='row'>
                <div className='col-12 mb-3'>
                  <h1 className='wi-main-title'>What’s Included:</h1>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-1.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Visits to the Care Navigation (GP)</span> for comprehensive healthcare guidance</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-2.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Consultations with a certified Diabetic Educator</span> who is a Nutritionist</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-1.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Consultations with a Family Medicine specialist</span> for overall health assessment</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-3.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Consultations with a Podiatrist</span> for foot health and related concerns</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-4.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Smoking cessation consultations</span> as needed</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-5.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Consultation with an Ophthalmologist</span> for eye health check-up</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-6.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Psychological consultation</span> for mental health assessment</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-7.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Audiology consultation</span> for hearing health assessment</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-8.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Visit to Dentist</span> for comprehensive assessment of oral health</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-9.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Lipid profile assessment</span> to monitor cholesterol levels</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-10.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>A1C</span> measurement for tracking average blood glucose levels over time</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-11.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Urinary Albumin-to-Creatinine Ratio</span> test to assess kidney function</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-9.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Serum Creatinine + Estimated Glomerular Filtration Rate (EGFR)</span> to evaluate kidney health.</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-9.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>ALT + AST</span> tests for liver function assessment</h2>
                </div>
            </div>

            <div className='row align-items-center mb-3'>
                <div className='col-auto'>
                    <img src={require("../../../images/icon-whats-12.png")} className='h-10' alt="" />
                </div>
                <div className='col ps-0'>
                    <h2 className='wi-content-text'><span>Influenza vaccine</span> administration to provide protection against the flu</h2>
                </div>
            </div>

        </section>

        <div className='bottom-fixed-button'>
            <button className="btn action-button btn-enrollNow" data-bs-toggle="modal" data-bs-target="#enrollNowModal">Enroll Now</button>
        </div> 


        <div className="modal fade" id="enrollNowModal" tabindex="-1" aria-labelledby="enrollNowModalLabel" data-bs-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
               
                <div className="modal-body">
                <div className="row">
                    <div className="col-12 my-[10px] text-center">
                        <img src={require("../../../images/party-popper.png")} className='d-inline' style={{height:"82px"}} alt="" />
                        <h2 className='maintitle my-3'>Congratulations !</h2>
                        <h3 className='JDW-content-text my-3'>We’re thrilled to have you on board. Get ready for a personalized and comprehensive approach to managing your diabetes and enhancing your overall well-being.</h3>
                        <h3 className='JDW-content-text my-3'>Book now for personalized guidance, tips, and expert advice.</h3>
                    </div>
                     
                    <div className="col-md-12 text-center mt-4">
                        <button className="btn action-button btn-enrollNow" onClick={navigatePage}>Book Now</button>
                    </div>
                    </div>

                </div>
                </div>
            </div>
            </div>


    </>
  );
};

export default WhatsIncluded;