import React from 'react';
import './CareNavigatorSlotProfile.css';

const CareNavigatorSlotProfile = () => {
  return (
    <>
        <section className='CareNavigatorSlotProfile'>
                <div className='card bg-main-CNSlotProfile-card'>
                    <div className='card-body p-2'>
                        <div className='row align-items-center'>
                            <div class="col-12 text-center">
                            <div class="CNSlotProfile-box">
                                <img src={require("../../../images/default-profile.png")} class="CNSlotProfile-box-img" alt=""/>
                                </div>
                            </div>
                            <div className='col-12 text-center mt-3'>
                                <h1 className='CNSlotProfile-main-title mb-2'>Amaira Shaikh</h1>
                                <h2 className='CNSlotProfile-sub-title'>Clinical Nutrition, Diabetes Educator and Weight Management</h2>
                                <h2 className='CNSlotProfile-sub-title mt-3'><span>I would love to get on a call to understand your health goals so that we can work towards healthy lifestyle.</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
           
        </section>        
    </>
  );
};

export default CareNavigatorSlotProfile;