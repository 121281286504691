import React from 'react';
import './HRACard.css';
import { Link } from 'react-router-dom';

const HRACard = ({hraData}) => {


  return (
    <>
        <section className='HRACard'>
            <div className='bg-main-blue'>
                <div className='card bg-main-card'>
                    <div className='card-body p-2'>
                        <div className='row align-items-center'>
                            <div className='col-auto'>
                                <img src={require("../../../images/icon-report-hra.png")} className='w-10' alt="" />
                            </div>
                            <div className='col ps-1'>
                                <h1 className='hra-main-title'>You’re at <span>{hraData.hraHeaderText}</span></h1>
                                {/* <h1 className='hra-main-title'>You’re at <span>Moderate Risk</span></h1> */}
                            </div>
                            <div className='col-12 mt-3'>
                                <h2 className='hra-content-text'>
                                While your HRA score is currently indicating a higher risk, remember that every positive step you take toward a healthier lifestyle can make a significant impact. We’re here to support you on your journey to improved well-being!
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row px-4 py-2 align-items-center'>
                    <div className='col px-1'>
                        <h3 className='hra-sub-title'>Your HRA Score is {hraData.hraScore}</h3>
                    </div>
                    <div className='col-auto px-0'>
                        <Link to ={`/hra-view-report/${hraData.uid}`} class="btn view-report-btn" >View Report</Link>
                        {/* <HRAViewReport hraData={hraData} /> */}
                    </div>
                </div>
            </div>
        </section>        
    </>
  );
};

export default HRACard;