import React, {useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NotificationToast({ message, type ,key}) {

    console.log("Reached toast")
    useEffect(() => {
        
        showToast();
      }, [message, type, key]);


      const showToast = () => {
          if (type === 'error') {
            toast.error(message);
          } else if (type === 'success') {
            toast.success(message);
          }
        };
  return <ToastContainer position="bottom-center" />;
}

export default NotificationToast;