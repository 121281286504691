import React, { useState } from 'react';
import { motion } from 'framer-motion';

function HRAQ8({ updateHraData, hraData }) {
    const [textareaValue, setTextareaValue] = useState('');

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const newFoodConditions = checked
            ? [...hraData.foodConditions, value]
            : hraData.foodConditions.filter((condition) => condition !== value);

        updateHraData('foodConditions', newFoodConditions);
    };

    const handleTextareaChange = (e) => {
        const { value } = e.target;
        setTextareaValue(value);
        updateHraData('foodSpecify', value); // Update the state for textarea value
    };

    return (
        <>
        <motion.div
            initial={{ opacity: 0, y: 20  }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className=''
        >
        <div className='row'>
            <div className="col-12 mb-3">
                <h1 className='hra-title'>Which of the following food groups are included in your daily routine (more than 4 times a week)? You can select more than one</h1>
            </div>
            <div className="col-12 mb-3">
                <div className="form-check d-flex">
                    <input
                        type="checkbox"
                        className="form-check-input form-check-input-ui"
                        id="foodCheckboxes"
                        value="Complex carbs (rice, oat, brown bread and brown pasta, quinoa)"
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor='foodCheckboxes' className="form-check-label form-label-input mt-[6px]">Complex carbs (rice, oat, brown bread and brown pasta, quinoa)</label>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-check d-flex">
                    <input
                        type="checkbox"
                        className="form-check-input form-check-input-ui"
                        id="foodCheckboxes1"
                        value="Simple carbs (white bread, white flour, cakes, and sweets. etc.)"
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor='foodCheckboxes1' className="form-check-label form-label-input mt-[6px]">Simple carbs (white bread, white flour, cakes, and sweets. etc.)</label>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-check d-flex">
                    <input
                        type="checkbox"
                        className="form-check-input form-check-input-ui"
                        id="foodCheckboxes2"
                        value="Fruit & vegetable (salad, cooked or raw)"
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor='foodCheckboxes2' className="form-check-label form-label-input mt-[6px]">Fruit & vegetable (salad, cooked or raw)</label>
                </div>
            </div>
            {/* Add more food group checkboxes here as needed */}
            <div className="col-12 mb-3">
                <label htmlFor='hraSub-3' className="form-label form-label-input">Specify</label>
                <textarea
                    className="form-control control-input-ui"
                    id="hraSub-3"
                    name="foodSpecify"
                    placeholder="Specify"
                    rows="3"
                    value={textareaValue}
                    onChange={handleTextareaChange}
                />
            </div>
        </div>
        </motion.div>
        </>
    );
}

export default HRAQ8;
