import React,{useState,useEffect} from 'react';
import CalendarView from "../component/CalendarView";
import FoodLogEntry from "../component/FoodLogEntry";
import { useLocation,useNavigate } from 'react-router-dom';
import { food_log_post_data,fetchVerifyData } from "../services/Services";
import NotificationToast from '../component/NotificationToast';
import { Helmet } from 'react-helmet';
import './FoodLog.css';


function FoodLog() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("goqiiUserId");
  const uuid = searchParams.get('uuid');
  const navigate = useNavigate();

  const [foodLogData, setFoodLogData] = useState('');
  const [foodNameData, setFoodNameData] = useState('');
  const [foodTimeData, setFoodTimeData] = useState('');
  const [foodCameraData, setFoodCameraData] = useState('');
  const [foodGalleryData, setFoodGalleryData] = useState('');
  const [calendarData, setCalendarData] = useState([]);
  const [foodPostData, setFoodPostData] = useState([]);
  const [isLogging, setIsLogging] = useState(false);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState('');
  const [verfiyUserAccess, setVerifyUserAccess] = useState([]);
  const [toastKey, setToastKey] = useState(0); // State variable for the key
  const [isLoading, setIsLoading] = useState(false); // State variable for loader
  const [disable, setDisable] = useState(true);
  
  
  useEffect(() => {
    fetchVerifyData();
  }, [uuid, success, errors]);

  // select calendar 
  const foodCalendarLog= (data) => {
    setCalendarData(data);
    console.log('parent Calendar Log',data)
  };

  //select food duration like breafast,lunch etc
  const foodDataLog= (data) => {
    setFoodLogData(data);
    console.log('parent Food Log',data)
  };

  //food name log 
  const foodNameLog= (data) => {
    setFoodNameData(data);
    console.log('parent time Log',data)
  };

  //food time log 
  const foodTimeLog= (data) => {
    setFoodTimeData(data);
    console.log('parent time Log',data)
  };

  //log camera
  const foodCameraLog= (data) => {
    setFoodCameraData(data);
    console.log('parent Camera Log',data)
  };

  //log gallery  
  const foodGalleryLog= (data) => {
    setFoodGalleryData(data);
    console.log('parent Gallery Log',data)
  };



 const submitFoodDataLog=async()=>{
  setDisable(false);
  console.log("final submit",calendarData,foodLogData,foodNameData,foodTimeData,foodCameraData,foodGalleryData);
  setIsLoading(true); // Show loader
  setErrors("");
    if (!foodLogData) {
        setIsLoading(false); // Hide loader
        setErrors("Please select one food log type.");
        setDisable(true);
    } else if (!foodNameData) {
        setIsLoading(false); // Hide loader
        setErrors("Please enter a valid food name.");
        setDisable(true);
    } else {
            try{

              let logData = {
                'logDate':calendarData,
                'logDuration':foodLogData,
                'logName':foodNameData,
                'logTime':foodTimeData
              }
              const formData = new FormData();
              formData.append('logGalleryImg', foodGalleryData);
              formData.append('logData', JSON.stringify(logData));
              console.log("logData",logData);
                const response = await food_log_post_data(formData);
                console.log("response food data post", response.data);
                if(response.data.code === 200){
                  setSuccess(response.data.data.message);
                  setFoodNameData('');
                  setFoodGalleryData('');
                  setDisable(true);
                  //sendDataToAndroid();
                }else{
                  setErrors(response.data.data.message);
                  setDisable(true);
                }
            } catch (error) {
              console.error(error);
              setErrors("An error occurred while submiting food log data.");
          }
          setIsLoading(false); // Hide loader
      }
    setToastKey(prevKey => prevKey + 1);
 }

 /*const sendDataToAndroid = () => {
  var dataToSend = {
    type: "close_screen",
    message: "Log Successfully"
  };
  window.Android.sendDataToAndroid(JSON.stringify(dataToSend));
}*/

  return (
    <>
      <Helmet>
        <title>Food Log</title>
      </Helmet>
      <CalendarView setCalendarData = {foodCalendarLog}/>
      <FoodLogEntry setFoodLogData = {foodDataLog} setFoodNameData = {foodNameLog} setFoodTimeData = {foodTimeLog} setFoodCameraData = {foodCameraLog} setFoodGalleryData = {foodGalleryLog} />
      <div className="floating-loader" style={{ display: isLoading ? 'block' : 'none' }}>
          <div className="loader"></div>
          Loading...
        </div>
      <section className='bottom-fixed-btn'>
      {/* {errors && <NotificationToast message={errors} key={toastKey} type="error"/>} */}
      {success && <NotificationToast message={success} key={toastKey} type="success" />}
        <div className='container'>
          <div className='row'>
          <div className='col-6'>
              <button type="button" className="btn btn-success back-btn">Cancel</button>
            </div>
            <div className='col-6'>
              <button type="button" className="btn btn-success next-btn" disabled={!disable} onClick={submitFoodDataLog}>{isLogging ? 'Loging...' : 'Log'}</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FoodLog;
