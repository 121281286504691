import React from 'react';
import './CoachDetailsCard.css';

const CoachDetailsCard = ({readData}) => {
  return (
    <>
        <section className='CoachDetailsCard'>
                <div className='card bg-main-CoachDetail-card'>
                    <div className='card-body p-2'>
                        <div className='row align-items-center'>
                            <div class="col-12 text-center">
                            <div class="CN-box">
                                <img src={require("../../../images/default-profile.png")} class="CN-box-img" alt=""/>
                                </div>
                            </div>
                            <div className='col-12 text-center mt-3'>
                                {/* <h1 className='CoachDetail-main-title mb-2'>Upcoming Call</h1> */}
                                
                                <h2 className='CoachDetail-sub-title mb-1'><span>Care Navigator - {readData.careNavigatorName}</span></h2>
                                <h2 className='CoachDetail-sub-title'>Your consultation with {readData.careNavigatorName} has been successfully completed.We value your feedback to ensure we provide the best service possible. Please take a moment to rate your experience on a scale of 1 to 5 stars, with 5 being the highest. Your input is greatly appreciated in helping us continually improve our services.</h2>
                            </div>
                        </div>
                    </div>
                </div>
           
        </section>        
    </>
  );
};

export default CoachDetailsCard;