import React, { useEffect, useState, useRef } from 'react';
import './CareNavigatorProfile.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';


import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

function CareNavigatorProfile() {
    return (
        <>
            <section className='CareNavigatorProfile'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-12 px-0'>
                            <Swiper
                                 slidesPerView={'auto'}
                                 centeredSlides={true}
                                 spaceBetween={16}
                                 pagination={{
                                   clickable: true,
                                 }}
                                 modules={[Pagination]}
                                 className="CareNavigatorProfileSlide"
                            >
                                <SwiperSlide>
                                    <>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20  }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2, duration: 0.5 }}
                                        className='CareNavigatorProfile-box'
                                        >
                                        <div className='CareNProfile-img-box'>
                                            <img src={require("../../../images/default-carenavigator.png")} className='' alt="" />
                                        </div>
                                        
                                        <div className='CareNProfile-box-gredient px-3 py-2 text-center'>
                                            <h2 className='CareNProfile-main-title mt-2 mb-2'>Amaira Shaikh</h2>
                                            <h3 className='CareNProfile-main-subtitle'>Clinical Nutrition, Diabetes Educator and Weight Management</h3>
                                        </div>

                                        <div className='CareNProfile-box-gredient px-3 py-2'>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>About me</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Experienced coach specializing in diabetes management, dedicated to providing personalized support and guidance.</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>My Mantra</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Health Happiness</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Activities</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Yoga practitioner</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Expertise</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Food Science and Nutrition</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Language</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>English</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12 py-3 px-4'>
                                                <Link to="/schedule-call" className="btn action-button btn-join">Select</Link>
                                            </div>
                                        </div>
                                    </motion.div>
                                    </>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20  }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2, duration: 0.5 }}
                                        className='CareNavigatorProfile-box'
                                        >
                                        <div className='CareNProfile-img-box'>
                                            <img src={require("../../../images/default-carenavigator.png")} className='' alt="" />
                                        </div>
                                        
                                        <div className='CareNProfile-box-gredient px-3 py-2 text-center'>
                                            <h2 className='CareNProfile-main-title mt-2 mb-2'>Amaira Shaikh</h2>
                                            <h3 className='CareNProfile-main-subtitle'>Clinical Nutrition, Diabetes Educator and Weight Management</h3>
                                        </div>

                                        <div className='CareNProfile-box-gredient px-3 py-2'>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>About me</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Experienced coach specializing in diabetes management, dedicated to providing personalized support and guidance.</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>My Mantra</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Health Happiness</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Activities</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Yoga practitioner</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Expertise</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Food Science and Nutrition</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Language</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>English</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12 py-3 px-4'>
                                            <Link to="/schedule-call" className="btn action-button btn-join">Select</Link>
                                            </div>
                                        </div>
                                    </motion.div>
                                    </>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20  }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2, duration: 0.5 }}
                                        className='CareNavigatorProfile-box'
                                        >
                                        <div className='CareNProfile-img-box'>
                                            <img src={require("../../../images/default-carenavigator.png")} className='' alt="" />
                                        </div>
                                        
                                        <div className='CareNProfile-box-gredient px-3 py-2 text-center'>
                                            <h2 className='CareNProfile-main-title mt-2 mb-2'>Amaira Shaikh</h2>
                                            <h3 className='CareNProfile-main-subtitle'>Clinical Nutrition, Diabetes Educator and Weight Management</h3>
                                        </div>

                                        <div className='CareNProfile-box-gredient px-3 py-2'>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>About me</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Experienced coach specializing in diabetes management, dedicated to providing personalized support and guidance.</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>My Mantra</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Health Happiness</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Activities</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Yoga practitioner</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Expertise</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>Food Science and Nutrition</h4>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-4'>
                                                    <h4 className='CareNProfile-box-content'><span>Language</span></h4>
                                                </div>
                                                <div className='col'>
                                                    <h4 className='CareNProfile-box-content'>English</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12 py-3 px-4'>
                                            <Link to="/schedule-call" className="btn action-button btn-join">Select</Link>
                                            </div>
                                        </div>
                                    </motion.div>
                                    </>
                                </SwiperSlide>
                                
                            </Swiper>
                         </div>                 
                    </div>
                           
                </div>
            </section>
        </>
    );
}

export default CareNavigatorProfile;
    