import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize 3D extension module
highcharts3d(Highcharts);

const Spo2Graph = ({ spoData, type }) => {
  let options={};
  useEffect(() => {
    console.log("useeffect",spoData);
    options = {
      chart: {
          type: 'line'
      },
      title: {
          text: '',
      },
      credits: {
          enabled: false,
        },
      xAxis: {
          categories: spoData.map(entry => entry.spo2Time),
          lineColor: 'transparent', // Set the line color to transparent
          lineWidth: 0,
      },
      yAxis: {
          min: 0,
          title: {
              text: 'SPO2'
          },
          gridLineWidth: 1, // Set the width of the grid lines
          gridLineDashStyle: 'dot',
          gridLineColor: '#646464',
          stackLabels: {
              enabled: true
          }
      },
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}'
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              pointPadding: 0.1, // Adjust the padding between columns
              borderWidth: 0, // Remove column border
              // pointWidth: 10, // Set the width of the columns
              borderRadius: 2,
              // dataLabels: {
              //     enabled: true
              // }
          }
      },
      series: [
          {
              name: 'Spo2',
              data: spoData.map(entry => parseFloat(entry.spo2Value)),
              stack: '',
          }/*,
          {
              name: 'Max Rate',
              data: spoData.map(entry => parseFloat(entry.maxRate)),
              stack: 'max rae',
          },
          {
              name: 'Last Rate',
              data: spoData.map(entry => parseFloat(entry.lastRate)),
              stack: 'last rate',
          }*/,
          {
              type: 'line',
              name: 'Target Line',
              data: Array(spoData.length).fill(1),
              color: '#4098E3', // Set your desired color here
              lineWidth: 0.5, // Adjust the thickness of the line
              marker: {
                  //enabled: true,
                  symbol: 'circle', // Set marker symbol to circle
                  radius: 2 // Adjust the radius to make it a small point
              },
              states: {
                  hover: {
                      lineWidth: 0
                  }
              }
          }],
  };
Highcharts.chart('chart-container', options);
}, [spoData]); // Re-render chart when stepData changes

  return (
    <div id="chart-container">
      <HighchartsReact highcharts={Highcharts} options={{}} />
    </div>
  );
};

export default Spo2Graph;
