import React, {useEffect} from 'react';
import './Arena.css';
import JoinCommunity from '../HomePage/component/JoinCommunity';

function Arena() {
    

  return (
    <>
   <section className='Arena'>
                <div className='container'>
                    <div className='row align-items-center'>
                    {/* <div className='col'>
                            <h1 className='Arena-title'>Arena</h1>
                    </div> */}
                    {/* <div className='col-12 mb-3 mt-[150px] text-center d-flex justify-content-center'>
                      <img src="https://appcdn.goqii.com/storeimg/98520_1719229340.png" alt="" className='h-[220px]'/>
                    </div>
                    <div className='col-12 mb-3 text-center'>
                    <h1 class="modal-title">Upcoming Feature:</h1>
                    <h4 class="modal-subtitle">Available in Phase 2!</h4>
                    </div> */}
                    <JoinCommunity/>
                        
                        
                    </div>
    </div>
    </section>
    </>
  );
}
export default Arena;