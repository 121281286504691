import React, { useState,useEffect } from 'react';
import './WeightLogEntry.css';
import NotificationToast from '../component/NotificationToast';
import { weight_log_post_data,unit_list_get_user_data,weight_list_get_user_data,user_delete_log_data } from '../services/Services';

function WeightLogEntry(props) {
  const defaultTimeSlept = '22:00'; // 10:00 PM in 24-hour format
  const [selectedTimeSlept, setSelectedTimeSlept] = useState(defaultTimeSlept);
  const [weight, setWeight] = useState('');
  const [waist, setWaist] = useState('');
  const [weightUnit, setWeightUnit] = useState('KG');
  const [waistUnit, setWaistUnit] = useState('CM');
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState('');
  const [toastKey, setToastKey] = useState(0); // State variable for the key
  const [isLoading, setIsLoading] = useState(false); // State variable for loader
  const [disable, setDisable] = useState(true);
  const [weightLogData, setWeightLogData] = useState([]);
  const [weightLogDataCount, setWeightLogDataCount] = useState(0);
  
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "weightInputTime") {
      setSelectedTimeSlept(value);
      props.setWeightTimeData(value);
    } else if (name === "weightInput") {
      if (/^\d*\.?\d*$/.test(value)) {
          setWeight(value);
      }else{
        setIsLoading(false); // Hide loader
        setErrors("Please enter a valid weight");
        setDisable(true);
      }
    } else if (name === "WaistInput") {
      if (/^\d*\.?\d*$/.test(value)) {
        setWaist(value);
      }else{
        setIsLoading(false); // Hide loader
        setErrors("Please enter a valid waist log");
        setDisable(true);
      }
    }
  };

  const submitWeightDataLog=async()=>{
    setDisable(false);
    console.log("final weight submit",selectedTimeSlept,props.calendarData,weight,waist);
    setIsLoading(true); // Show loader
    setErrors("");
    if(weight <= 0){
      setIsLoading(false); // Hide loader
      setErrors("Please enter a valid weight log");
      setDisable(true);
    }else if(waist <= 0){
      setIsLoading(false); // Hide loader
      setErrors("Please select a valid waist log");
      setDisable(true);

    }else{
          let logData = {
            'logDate':props.calendarData,
            'logTime':selectedTimeSlept,
            'weight':weight,
            'weightUnit':weightUnit,
            'waist':waist,
            'waistUnit':waistUnit
          }
          try {
              const response = await weight_log_post_data(logData);
              //console.log("response weight data post", response.data);
              if(response.data.code === 200){
                fetchData();
                fetchWeightData();
                setSuccess(response.data.data.message);
                //sendDataToAndroid();
                setDisable(true);
              }else{
                setErrors(response.data.data.message);
                setDisable(true);
              }
            } catch (error) {
              console.error(error);
              setErrors("An error occurred while submitting weight log data.");
              setDisable(false);
            }
            setIsLoading(false); // Hide loader          
    }
    //setFoodPostData(response.data.data);
    setToastKey(prevKey => prevKey + 1);
  }


  const fetchData = async () => {
    try {
      console.log("fetch data");
        const response = await unit_list_get_user_data();
        console.log("response unit_list_get_user_data", response.data.data);
        console.log("response.data.data.length",response.data.data.length);
        setWeightUnit(response.data.data[0].weightUnit);
        setWaistUnit(response.data.data[1].waistUnit);
    } catch (error) {
      console.error(error);
      setErrors("An error occurred while fetching weight log data.");
      setDisable(false);
    }
};

const fetchWeightData = async () =>{
  try {
    console.log("fetch data weight");
      const response = await weight_list_get_user_data(props.calendarData);
      console.log("response weight_list_get_user_data", response.data.data);
      setWeightLogData(response.data.data);
      console.log("response.data.data.length weight",response.data.data.length);
      setWeightLogDataCount(response.data.data.length);
  } catch (error) {
      console.error(error);
      setErrors("An error occurred while fetching weight log data.");
      setDisable(false);
  }

}

const userDeleteLog = async(deleteLogId,type) =>{
  let response;
try {
    var data = {
      type: type,
      deleteLogId: deleteLogId
    };
    console.log("user delete Log",deleteLogId);
    response = await user_delete_log_data(JSON.stringify(data));
    if(response.data.code === 200){
      fetchData();
      fetchWeightData();
      setSuccess(response.data.data.message)
    }else{
      setErrors("An error occurred while deleting weight data.");
    }
  } catch (error) {
      console.error(error);
      setErrors("An error occurred while deleting weight data.");
  }
  return response;
}


useEffect(() => {
    fetchData();
    fetchWeightData();
  },[success,errors,props.calendarData]);

  return (
    <>
      <section className="WeightLogEntry">
      <div className="floating-loader" style={{ display: isLoading ? 'block' : 'none' }}>
          <div className="loader"></div>
          loading...
        </div>
        <div className="container">
          <div className='row align-items-center'>
            <div className='col-12'>
              <h1 className='weight-main-title'>Log New Weight</h1>
            </div>
            <div className='col-12 mt-3'>
              <label className='weight-box' htmlFor='weightInputTime'>
                <div className='row align-items-center'>
                  <div className='col-auto'>
                    <img src={require(`../images/ic_time_green.png`)} className="weight-icon-new" alt="" />
                  </div>
                  <div className='col ps-0'>
                    <h3 className="weight-box-title ">Time</h3>
                  </div>
                  <div className='col ps-0'>
                    <input
                      type="time"
                      className="weight-box-title1"
                      id='weightInputTime'
                      name='weightInputTime'
                      defaultValue={defaultTimeSlept}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </label>

              <label className='weight-box mt-2' htmlFor='weightInput'>
                <div className='row align-items-center'>
                  <div className='col-auto'>
                    <img src={require(`../images/ic_weight_green.png`)} className="weight-icon-new" alt="" />
                  </div>
                  <div className='col ps-0'>
                    <h3 className="weight-box-title">Weight  </h3>
                  </div>
                  <div className='col ps-0'>
                  <input
                      type="text"
                      className="weight-box-title1"
                      id='weightInput'
                      name='weightInput'
                     value={weight}
                      onChange={handleInputChange}
                    />
                  <font className="weight-box-title">{weightUnit}  </font>
                  </div>
                </div>
              </label>

              <label className='weight-box mt-2' htmlFor='WaistInput'>
                <div className='row align-items-center'>
                  <div className='col-auto'>
                    <img src={require(`../images/ic_waist_green.png`)} className="weight-icon-new" alt="" />
                  </div>
                  <div className='col ps-0'>
                    <h3 className="weight-box-title">Waist</h3>
                  </div>
                  <div className='col ps-0'>
                  <input
                      type="text"
                      className="weight-box-title1"
                      id='WaistInput'
                      name='WaistInput'
                     value={waist}
                      onChange={handleInputChange}
                    /> 
                  <font className="weight-box-title">{waistUnit}  </font>
                  </div>
                </div>
              </label>
            </div>
          </div>
          {/* {errors && <NotificationToast message={errors} key={toastKey} type="error"/>} */}
          {success && <NotificationToast message={success} key={toastKey} type="success" />}
          <div className='row mt-5'>
            <div className='col-6'>
              <button type="button" className="btn btn-success back-btn">Cancel</button>
            </div>
            <div className='col-6'>
              <button type="button" className="btn btn-success next-btn" disabled={!disable} onClick={submitWeightDataLog}>Log</button>
            </div>
            <div className='col-12 mt-3'>
              <hr />
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <h2 className='weight-detail-title'>
                Logged weight
              </h2>
            </div>
            <div className='col-12 px-4'>
            {weightLogDataCount === 0 ? (
                <div className="text-center">No weight log found.</div>
              ) : (
                weightLogData.map(weightItem => (
              <div className='row align-items-center py-3 border-bottom' key={weightItem.bodyLogId}>
                <div className='col-auto'>
                  <img src={require(`../images/ic_weight_green.png`)} className="weight-icon1" alt="" />
                </div>
                <div className='col ps-0'>
                  <h3 className='form-label1'>Weight</h3>
                  <h3 className='form-label2'>{weightItem.weight} {weightItem.unweightUnitt}</h3>
                </div>
                <div className='col ps-0'>
                  <h3 className='form-label1'>Logged at</h3>
                  <h3 className='form-label2'>{weightItem.logTime}</h3>
                  <h3 className='form-label2' onClick={(e) => userDeleteLog(weightItem.bodyLogId,'weight')}><i class='bx bx-trash'></i></h3>
                </div>
              </div>
                 ))
                 )}
            </div>
          </div>

        </div>
      </section>

      {/* <div className="modal fade" id="weightModal" tabIndex="-1" aria-labelledby="weightModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content WeightLogEntryModule">
            <div className="modal-body">
              <WeightPicker />
            </div>
            <div className="modal-footer text-center">
              <button type="button" className="btn weight-btn" data-bs-dismiss="modal">CANCEL</button>
              <button type="button" className="btn weight-btn" onClick={handleDoneClick}>DONE</button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default WeightLogEntry;
