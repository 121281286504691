import React,{useState,useEffect} from 'react';
import './UpcomingCall.css';
import { Link, Navigate } from 'react-router-dom';
import { reverify_users_access, upcoming_call_list } from '../../../services/Services';

const UpcomingCall = ({dataUid}) => {
const [readData, setReadData] = useState([]);

  useEffect(() => {
      fetchData();
      verifyUsers();
  },[]);

  const verifyUsers = async()=>{
    try {
      const response = await reverify_users_access(dataUid);
      if(response.data.code == 200){
        Navigate(response.data.data.url);
      }
  } catch (error) {
      console.error(error);
  }
}

  const fetchData =async()=>{
    try {
  
        const response = await upcoming_call_list(dataUid);
        console.log("fetch_upcoming_call_list", response.data);
        setReadData(response.data.data);
    } catch (error) {
        console.error(error);
    }
}
  
const handleJoinClick = () => {
    // Create the JSON object
    const jsonData = {
      type:'open_tab',
      title: 'user carenavigator feedback',
      url: 'https://healthengage.goqii.com/rate-cn-consultation/'+dataUid+'/'+readData.careNavigatorId
    };

    // Convert the JSON object to a string
    const jsonString = JSON.stringify(jsonData);

    if (window.Android && window.Android.sendJsonData) {
        window.Android.sendJsonData(jsonString);
      }
      // For iOS
      else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.sendJsonData
      ) {
        window.webkit.messageHandlers.sendJsonData.postMessage(jsonString);
      }
  };
  return (
    <>
        <section className='UpcomingCall'>
                <div className='card bg-main-UC-card'>
                    <div className='card-body p-2'>
                        <div className='row align-items-center'>
                        <div class="col-auto text-center">
                            <div class="CNbox">
                                <img src={require("../../../images/default-profile.png")} class="CNbox-img" alt=""/>
                                </div>
                            </div>
                            <div className='col ps-1'>
                                <h1 className='UC-main-title mb-2'>Upcoming Call</h1>
                                <h2 className='UC-sub-title'>Care Navigator - {readData.careNavigatorName}</h2>
                                <h2 className='UC-sub-title'><span>{readData.dateScheduled}</span></h2>
                                <div className='row align-items-center mt-2'>
                                    {/* <div className='col'>
                                        <h2 className='UC-sub-title'><span>Starting in 01:04 mins</span></h2>
                                    </div> */}
                                    {readData.isWithinNext10Minutes ? ( <div className='col-auto ps-0'>
                                        {/* <Link className='btn btn-join-new px-4' to={`/rate-cn-consultation/${dataUid}/${readData.careNavigatorId}/${submitUrl}`} >Join</Link> */}
                                        <button className='btn btn-join-new px-4' onClick={handleJoinClick} >Join</button>
                                    </div>) : ('')}
                                    
                                </div>
                            </div>
                            
                        </div>
                        
                        
                    </div>
                </div>
           
        </section>        
    </>
  );
};

export default UpcomingCall;