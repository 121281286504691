import React from 'react';
import './ErrorPage.css';

function ErrorPage() {
  return (
    <div className="error-container">
      <img src="https://appcdn.goqii.com/storeimg/30874_1713170925.png" alt="Error" className="error-image" />
      <h1 className="error-message">Oops! Something went wrong.</h1>
      <p>We couldn't find the page you're looking for.</p>
      <p>Go back to <a href="/" className="error-link">homepage</a>.</p>
    </div>
  );
}

export default ErrorPage;
