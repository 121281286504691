import React from 'react';
import { motion } from 'framer-motion';

import './HomePage.css';
import CalendarHome from './component/CalendarHome';
import LogBoxes from './component/LogBoxes';
import HabitsProgress from './component/HabitsProgress';
import RecommendedReads from './component/RecommendedReads';
import RecommendedVideos from './component/RecommendedVideos';
import JoinCommunity from './component/JoinCommunity';
import UpcomingAppointments from './component/UpcomingAppointments';
import UpcomingLabTests from './component/UpcomingLabTests';
import MedicineOrderStats from './component/MedicineOrderStats';
import HomeHRA from './component/HomeHRA';
import { useLocation } from 'react-router-dom';


function HomePage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uidParam = searchParams.get('uid');
  localStorage.setItem('uid', uidParam);

    return (
    <>
    <motion.section 
        className="HomePage mb-[80px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.1 }}
        >
            <UpcomingAppointments/>
            <UpcomingLabTests/>
            <MedicineOrderStats/>
            <CalendarHome/>
            <LogBoxes/>
            <HabitsProgress/>
            <HomeHRA/>
            <RecommendedReads/>
            <RecommendedVideos/>
            <JoinCommunity/>
        </motion.section>

    </>
  );
}

export default HomePage;
