import React, { useState } from 'react';
import HomeHodNavbar from "./component/HomeHodNav/HomeHodNavbar";
import HomeBottomMenu from "./component/HomeHodNav/HomeBottomMenu";
import MainPage from './pages/MainPage/MainPage';

function Protected({ Component, title, childrenProps, coachProfile, bottomMenu, tab, homeTab }) {
  const [activeTab, setActiveTab] = useState(tab || 'hometab1');

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
    return (
      <>
       
        <section>
          <HomeHodNavbar title={title} coachProfile={coachProfile} />
          <Component {...childrenProps} />
          {homeTab === "Y" && (
            <>
          <MainPage activeTab={activeTab} homeTab={homeTab} /> 
          </>
          )}
          <HomeBottomMenu bottomMenu={bottomMenu} handleTabClick={handleTabClick} activeTab={activeTab} />
        </section>
      </>
    );
  }
  
  export default Protected;