import React from 'react';
import './StatsSteps.css';

const StatsSteps = () => {
  return (
    <>
        <section className='StatsSteps'>
                <div className='card bg-main-StatsSteps-card'>
                    <div className='card-body p-2'>

                        {/* <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSteps-sub-title'>Active Time</h2>
                            </div>
                            <div className='col-6'>
                                <h2 className='StatsSteps-content-title'>0<span> mins</span></h2>
                            </div>
                        </div>

                        <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSteps-sub-title'>Activity Time</h2>
                            </div>
                            <div className='col-6'>
                                <h2 className='StatsSteps-content-title'>0<span> mins</span></h2>
                            </div>
                        </div> */}

                        <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSteps-sub-title'>Total Distance</h2>
                            </div>
                            <div className='col-6'>
                                <h2 className='StatsSteps-content-title'>3.2<span> Km</span></h2>
                            </div>
                        </div>

                        <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSteps-sub-title'>Total Steps</h2>
                            </div>
                            <div className='col-6'>
                                <h2 className='StatsSteps-content-title'>4,300<span> Steps</span></h2>
                            </div>
                        </div>

                        {/* <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSteps-sub-title'>Google Fit Steps</h2>
                            </div>
                            <div className='col-6'>
                                <h2 className='StatsSteps-content-title'>5,593<span> Steps</span></h2>
                            </div>
                        </div> */}

                    </div>
                </div>
           
        </section>        
    </>
  );
};

export default StatsSteps;