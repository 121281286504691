import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize 3D extension module
highcharts3d(Highcharts);

const WaterGraph = ({ waterData, type }) => {
  let options={};
  let color1 = "#4098E3";
  let color2 = "#4098E3";
  let color3 = "#4098E3";
  useEffect(() => {
    if(type === 'daily'){
      options = {
        chart: {
          type: 'column',
          height: 300,
          // margin: [10, 0, 50, 50],
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          title: {
            text: 'Time', // Set the title for xAxis
          },
          //categories: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
          categories: waterData.map(item => item.waterTime), // Assuming data contains objects with a 'name' property
          lineColor: 'transparent', // Set the line color to transparent
          lineWidth: 0,
        },
          yAxis: {
          title: {
            text: 'Value',
          },
          gridLineWidth: 1, // Set the width of the grid lines
          gridLineDashStyle: 'dot',
          gridLineColor: '#646464',
        },
        plotOptions: {
          column: {
            pointPadding: 0.1, // Adjust the padding between columns
            borderWidth: 0, // Remove column border
            pointWidth: 15, // Set the width of the columns
            //borderRadius: 30,
            dataLabels: {
              enabled: true,
              color: '#333',
              style: {
                fontWeight: 'bold',
              },
              formatter: function() {
                // Customize the label text based on the data point
                return this.y
              }
            }
          },
          line: {
            dashStyle: 'dot', 
            color: '#646464'
          },
        },
        series: [
          {
            name: '',
            showInLegend: false,
            data: waterData.map(item => ({
              y: parseInt(item.waterlog), // Assuming each item has a 'value' property
              color: item.waterlog > 900 ? color1 :
              item.waterlog > 1500 ? color2 : color3
            })),
            marker: {
              enabled: false, // Hide the series label dot
            },
          },
        ],
      };
      Highcharts.chart('chart-container', options);
  }else if(type === 'weekly'){

     options = {
      chart: {
        type: 'column',
        height: 300,
        // margin: [10, 0, 50, 50],
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: 'Time', // Set the title for xAxis
        },
        //categories: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        categories: waterData.map(item => item.waterTime), // Assuming data contains objects with a 'name' property
        lineColor: 'transparent', // Set the line color to transparent
        lineWidth: 0,
      },
        yAxis: {
        title: {
          text: 'Value',
        },
        gridLineWidth: 1, // Set the width of the grid lines
        gridLineDashStyle: 'dot',
        gridLineColor: '#646464',
        // plotLines: [
        //   {
        //     color: '#1a0f91', // Set the color of the grid line
        //     dashStyle: 'dot', // Make the grid line dotted
        //     width: 2, // Set the width of the grid line
        //     value:6 /* Set the y-axis value where you want to place the line */ ,
        //     zIndex: 5, // Set the zIndex to make sure it's above the columns
        //     label: {
        //       text: 'Target Steps: 6000', // Text to display on the plot line
        //       align: 'right', // Alignment of the text
        //       x: 0, // Offset from the right edge of the plot area
        //       style: {
        //         color: '#333', // Text color
        //         fontWeight: '500' // Text style
        //       }
        //     }
        //   },
        // ],
      },
      plotOptions: {
        column: {
          pointPadding: 0.1, // Adjust the padding between columns
          borderWidth: 0, // Remove column border
          pointWidth: 15, // Set the width of the columns
          //borderRadius: 30,
          dataLabels: {
            enabled: true,
            color: '#333',
            style: {
              fontWeight: 'bold',
            },
            formatter: function() {
              // Customize the label text based on the data point
              return this.y;
            }
          }
        },
        line: {
          dashStyle: 'dot', 
          color: '#646464'
        },
      },
      series: [
        {
          name: 'Days',
          showInLegend: false,
          data: waterData.map(item => ({
            y: parseInt(item.waterlog), // Assuming each item has a 'value' property
            color: item.waterlog > 1000 ? color1 :
            item.waterlog > 700 ? color2 : color3
          })),
          marker: {
            enabled: false, // Hide the series label dot
          },
        },
      ],
    };
    Highcharts.chart('chart-container', options);
  }else if(type === 'monthly'){
console.log("monthly")
     options = {
      chart: {
        type: 'column',
        height: 300,
        // width: 600,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: waterData.map(item => item.waterTime), // Assuming data contains objects with a 'day' property representing day numbers
        title: {
          text: 'Week',
        },
        lineColor: 'transparent', // Set the line color to transparent
        lineWidth: 0,
      },
      yAxis: {
        title: {
          text: 'Value',
        },
        gridLineWidth: 1, // Set the width of the grid lines
        gridLineDashStyle: 'dot',
        gridLineColor: '#646464',
        // plotLines: [
        //   {
        //     color: '#1a0f91', // Set the color of the grid line
        //     dashStyle: 'dot', // Make the grid line dotted
        //     width: 2, // Set the width of the grid line
        //     value:6 /* Set the y-axis value where you want to place the line */ ,
        //     zIndex: 5, // Set the zIndex to make sure it's above the columns
        //     label: {
        //       text: 'Target Steps: 6000', // Text to display on the plot line
        //       align: 'right', // Alignment of the text
        //       x: 0, // Offset from the right edge of the plot area
        //       style: {
        //         color: '#333', // Text color
        //         fontWeight: '500' // Text style
        //       }
        //     }
        //   },
        // ],
      },
      plotOptions: {
        column: {
          pointPadding: 0.1, // Adjust the padding between columns
          borderWidth: 0, // Remove column border
          pointWidth: 15, // Set the width of the columns
          //borderRadius: 30,
          dataLabels: {
            enabled: true,
            color: '#333',
            style: {
              fontWeight: 'bold',
            },
            formatter: function() {
              // Customize the label text based on the data point
               return this.y;
            }
          }
        },
        line: {
          dashStyle: 'dot', 
          color: '#646464'
        },
      },
      series: [{
        name: 'Week',
        showInLegend: false,
        data: waterData.map(item => ({
          y: parseInt(item.waterlog), // Assuming each item has a 'value' property
          color: item.waterlog > 900 ? color1 :
          item.waterlog > 1500 ? color2 : color3
        })),
        
      }],
    };
    Highcharts.chart('chart-container', options);
  }else if(type === 'yearly'){
     options = {
      chart: {
        type: 'column',
        height: 300,
        // width: 600,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: waterData.map(item => item.waterTime), // Assuming data contains objects with a 'month' property representing month names or numbers
        title: {
          text: 'Month',
        },
        lineColor: 'transparent', // Set the line color to transparent
        lineWidth: 0,
      },
      yAxis: {
        title: {
          text: 'Value',
        },
        gridLineWidth: 1, // Set the width of the grid lines
        gridLineDashStyle: 'dot',
        gridLineColor: '#646464',
        plotLines: [
          {
            color: '#1a0f91', // Set the color of the grid line
            dashStyle: 'dot', // Make the grid line dotted
            width: 2, // Set the width of the grid line
            value:6 /* Set the y-axis value where you want to place the line */ ,
            zIndex: 5, // Set the zIndex to make sure it's above the columns
            label: {
              text: 'Target Steps: 6000', // Text to display on the plot line
              align: 'right', // Alignment of the text
              x: 0, // Offset from the right edge of the plot area
              style: {
                color: '#333', // Text color
                fontWeight: '500' // Text style
              }
            }
          },
        ],
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0,
          //borderRadius: 30,
          pointWidth: 15,
          dataLabels: {
            enabled: true,
            color: '#333',
            style: {
              fontWeight: 'bold',
            },
            formatter: function() {
              // Customize the label text based on the data point
              return this.y;
            }
          }
        }
      },
      series: [
        {
          name: '',
          showInLegend: false,
          data: waterData.map(item => ({
            y: parseInt(item.waterlog), // Assuming each item has a 'value' property
            color: item.waterlog > 900 ? color1 :
            item.waterlog > 1500 ? color2 : color3
          })),
          marker: {
            enabled: false, // Hide the series label dot
          },
        },
      ],
    };
    Highcharts.chart('chart-container', options);
  }
   
  }, [waterData]); // Re-render chart when stepData changes

  return (
    <div id="chart-container">
      <HighchartsReact highcharts={Highcharts} options={{}} />
    </div>
  );
};

export default WaterGraph;
