import React,{useState,useEffect} from 'react';
import './RecommendedVideos.css';
import { homepage_recommended_video } from '../../../services/Services';

function RecommendedVideos() {
    const [readData, setReadData] = useState([]);
    const uid = localStorage.getItem('uid');
      
    useEffect(() => {
      fetchData();
  },[uid]);
  
  const fetchData =async()=>{
    try {
  
        const response = await homepage_recommended_video(uid);
        console.log("homepage_recommended_video", response.data.data);
        setReadData(response.data.data);
    } catch (error) {
        console.error(error);
    }
  }

//   const openInNewTab = (url) => {
//     const isMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
//     if (isMobile) {
//       window.location.href = url;
//     } else {
//       const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
//       if (newWindow) newWindow.opener = null;
//     }
// };

const handleClick = (videoUrl,videoTitle) => {
    console.log("videoUrl",videoUrl);
    console.log("videoUrl",videoTitle);
    // Create the JSON object
    const jsonData = {
      type:'open_tab',
      title: videoTitle,
      url: videoUrl
    };

    // Convert the JSON object to a string
    const jsonString = JSON.stringify(jsonData);

    if (window.Android && window.Android.sendJsonData) {
        window.Android.sendJsonData(jsonString);
      }
      // For iOS
      else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.sendJsonData
      ) {
        window.webkit.messageHandlers.sendJsonData.postMessage(jsonString);
      }
  };

  return (
    <>
        <section className='RecommendedVideos mt-4'>
            <div className='container'>
                <div className='row align-items-center mb-2'>
                <div className='col'>
                        <h1 className='Videos-title'>Recommended Videos</h1>
                    </div>
                    {/* <div className='col-auto'>
                        <button type="button" class="btn view-all">View All</button>
                    </div> */}
                </div>
                <div className='row align-items-center'>
                {readData.map((item, index) => (
                    <div className='col-6 px-2'  key={index}>
                        <div className='RecommendedVideos-box' onClick={() => handleClick(item.videoUrl,item.VideoTitle)}>
                            <img src={item.imgageUrl} className="Videos-img" alt="" />
                            <div className='Videos-box-gredient'>
                                <h2 className='Videos-box-title mb-0 h-8'>{item.VideoTitle}</h2>

                                {/* <div className='row'>
                                    <div className='col-4'>
                                        <h2 className='Videos-box-title'><i class='bx bx-show-alt' style={{verticalAlign:'middle'}}></i> <span>84k</span></h2>
                                    </div>
                                    <div className='col-4'>
                                        <h2 className='Videos-box-title'><i class='bx bx-message-rounded' style={{verticalAlign:'middle'}}></i> <span>228</span></h2>
                                    </div>
                                    <div className='col-4'>
                                        <h2 className='Videos-box-title'><i class='bx bx-heart' style={{verticalAlign:'middle'}}></i> <span>51k</span></h2>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))}
                    {/* <div className='col-6 px-2'>
                        <div className='RecommendedVideos-box'>
                            <img src="https://appcdn.goqii.com/storeimg/5225_1723364034.png" className="Videos-img" alt="" />
                            <div className='Videos-box-gredient'>
                                <h2 className='Videos-box-title mb-2 h-8'>Can Diabetics eat Fruits ?</h2>

                                <div className='row'>
                                    <div className='col-4'>
                                        <h2 className='Videos-box-title'><i class='bx bx-show-alt' style={{verticalAlign:'middle'}}></i> <span>84k</span></h2>
                                    </div>
                                    <div className='col-4'>
                                        <h2 className='Videos-box-title'><i class='bx bx-message-rounded' style={{verticalAlign:'middle'}}></i> <span>228</span></h2>
                                    </div>
                                    <div className='col-4'>
                                        <h2 className='Videos-box-title'><i class='bx bx-heart' style={{verticalAlign:'middle'}}></i> <span>51k</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>
        </section>

    </>
  );
}

export default RecommendedVideos;
