
import React  from "react";
import { useLocation } from 'react-router-dom';
import HabitMenu from "../component/HabitMenu";

const Habits =()=>{
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let tab = searchParams.get("tab");
    if (!tab) {
        tab = "tab1"; // Set your default tab value here
    }
    return(
        <>
        <HabitMenu tab={tab}/>
        </>
    );
}
export default Habits
