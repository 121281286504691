import React from 'react';
import './HomeCard.css';
import { motion } from "framer-motion";
const HomeCard = () => {
  return (
    <>
    <motion.section 
        className="home-card"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
      <motion.div
        initial={{ opacity: 0, y: 20  }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        >
        <div className='container'>
          <div class="row">
              <div className='col-12'>
                  <div className='card homecard-bg'>
                    <div className='card-body'>
                        <div className='row'>
                          <div className='col-auto'>
                              <img src={require("../../images/icon-home-Diabetic.png")} className='h-10 w-10' alt="" />
                          </div>
                          <div className='col px-0'>
                              <h1 className='main-title'>Unlock Your Well Being :</h1>
                              <h2 className='main-subtitle'>Diabetic Wellness Program</h2>
                          </div>
                          <div className='col-auto'>
                          <button className="btn btn-primary enroll-now-btn">Enroll Now</button>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        </motion.div>
    </motion.section >
    </>
  );
};

export default HomeCard;