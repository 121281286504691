import React from 'react';
import { motion } from 'framer-motion';

import './CareNavigatorChat.css';


function CareNavigatorChat() {
  return (
    <>
    <motion.section 
        className="CareNavigatorChat"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.1 }}
        >
        <img src="https://appcdn.goqii.com/storeimg/81786_1721026288.jpg" className='w-full' alt="" />
        </motion.section>

    </>
  );
}

export default CareNavigatorChat;
