import React from 'react';
import { motion } from 'framer-motion';

function HRAQ5({ updateHraData, hraData }) {
    
    const handleRadioChange = (e) => {
        const { value, name } = e.target;
        updateHraData(name, value);  // Update the parent component's state
      };
  return (
    <>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      className=''
    >
      <div className='row'>
        <div className="col-12 mb-3">
          <h1 className='hra-title'>How often do you exercise?</h1>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="radio"
              className="form-check-input form-radio-input-ui"
              name="exerciseRadios"
              id="hraRadios10"
              value="No exercises"
              onChange={handleRadioChange}
            />
            <label htmlFor='hraRadios10' className="form-check-label form-label-input mt-[6px]">No exercises</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="radio"
              className="form-check-input form-radio-input-ui"
              name="exerciseRadios"
              id="hraRadios11"
              value="1-3 times per week"
              onChange={handleRadioChange}
            />
            <label htmlFor='hraRadios11' className="form-check-label form-label-input mt-[6px]">1-3 times per week</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="radio"
              className="form-check-input form-radio-input-ui"
              name="exerciseRadios"
              id="hraRadios12"
              value="3-5 times per week or above"
              onChange={handleRadioChange}
            />
            <label htmlFor='hraRadios12' className="form-check-label form-label-input mt-[6px]">3-5 times per week or above</label>
          </div>
        </div>
      </div>
    </motion.div>
    </>
  );
}

export default HRAQ5;
