import React from 'react';
import { motion } from 'framer-motion';
import CareNavigatorSlotProfile from './component/CareNavigatorSlotProfile';
import CareNavigatorSlotBook from './component/CareNavigatorSlotBook';

import './ScheduleCall.css';


function ScheduleCall() {
  return (
    <>
    <motion.section 
        className="ScheduleCall mt-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >

        <div className='container'>
          
          <div className='row'>
              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        >
                          <CareNavigatorSlotProfile/>
                    </motion.div>
              </div>
              
              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        >
                        <CareNavigatorSlotBook/>
                    </motion.div>
              </div>
          </div>
        </div>
        
    </motion.section>

    </>
  );
}

export default ScheduleCall;
