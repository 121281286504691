// import React, {useState} from 'react';
import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


import '../css/HabitMenu.css'
import Walk from './Walk';
import Sleep from './Sleep';
import HeartRate from './HeartRate';
import Spo2 from './Spo2';
import Water from './Water';


const HabitMenu=({tab})=>{
console.log("tab", tab);
    const [activeTab, setActiveTab] = useState(tab);
   
    // Function to handle tab click
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    return(
        <>
            <section>
                <div className='container'>
                    <div className='row'>
                            {/* Tab buttons */}
                            <div className='col-12 px-0'>
                                <>
                                <Swiper
                                    slidesPerView={'auto'}
                                    spaceBetween={0}
                                    pagination={{
                                    clickable: false,
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <button onClick={() => handleTabClick('tab1')} className={activeTab === 'tab1' ? 'tab-button active' : 'tab-button'}>Step</button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button onClick={() => handleTabClick('tab2')} className={activeTab === 'tab2' ? 'tab-button active' : 'tab-button'}>Sleep</button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button onClick={() => handleTabClick('tab3')} className={activeTab === 'tab3' ? 'tab-button active' : 'tab-button'}>Heart Rate</button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button onClick={() => handleTabClick('tab4')} className={activeTab === 'tab4' ? 'tab-button active' : 'tab-button'}>Spo2</button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button onClick={() => handleTabClick('tab5')} className={activeTab === 'tab5' ? 'tab-button active' : 'tab-button'}>Water</button>
                                    </SwiperSlide>
                                </Swiper>
                                </>
                            </div>

                            {/* Tab content */}
                            <div className='col-12 px-0'>
                                {activeTab === 'tab1' && <div><Walk activity={'step'}/></div>}
                                {activeTab === 'tab2' && <div><Sleep activity={'sleep'}/></div>}
                                {activeTab === 'tab3' && <div><HeartRate activity={'heartrate'}/></div>}
                                {activeTab === 'tab4' && <div><Spo2 activity={'spo'}/></div>}
                                {activeTab === 'tab5' && <div><Water activity={'water'}/></div>}
                            </div>
                        </div>
                    </div>
            </section>
        </>
    );

}

export default HabitMenu