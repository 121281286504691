import React from 'react';

import './HomeHodNavbar.css';

const sendDataToReactNative = (data) => {
    localStorage.setItem('dataForReactNative', JSON.stringify(data));
    // Redirect to React Native app URL
    window.location.href = 'your-react-native-app://';
  };

function HomeHodNavbar({ title, coachProfile }) {
    console.log('coachProfile:', coachProfile);

    const dataToSend = { message: 'Hello from GOQii React Web!' };
    
  return (
    <>
        {/* <section className='HomeHodNavbar'> 
            <div className='container'>
                <div className='row align-items-center'>
                
                    <div className='col-auto px-0'>
                        <button className='btn back-btn'>
                            <i class='bx bx-left-arrow-alt'></i>
                        </button>
                    </div>
                    <div className='col ps-0'>
                        <h1 className='hod-title'>{title}</h1>
                    </div>
                    {coachProfile === "Y" && (
                            <div className='col-auto text-center px-0' onClick={() => sendDataToReactNative(dataToSend)}>
                                <div className='coachbox'>
                                    <img src={require("../../images/default-profile.png")} className="coachbox-img" alt=""/>
                                </div>
                            </div>
                    )} */}
                    {/* this code hide by vinod --start */}
                    {/* <div className='col-auto text-center'>
                        <div className='coachbox'>
                            <img src={require('../../images/tracker-image.png')} className="coachbox-img" alt="" />

                            <img src={require('../../images/tick.png')} className="tick-icon" alt="" />
                        </div>
                        <h3 className='coach-name'>Synced</h3>
                    </div> */}
                    {/* end */}
                {/* </div>
            </div>
        </section> */}
    </>
  );
}

export default HomeHodNavbar;
