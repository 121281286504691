import React, {useState, useRef} from 'react';
import './FoodLogEntry.css';
// import { useDropzone } from 'react-dropzone';
// import PermissionsAndroid from 'react';
//import { FileUploader } from "react-drag-drop-files";



function FoodLogEntry(props) {
  //const fileTypes = ["JPG", "PNG", "GIF"];
  //const videoRef = useRef();
  const [activeCategory, setActiveCategory] = useState('');
  //const canvasRef = useRef();
  //const [stream, setStream] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputValue, setInputValue] = useState();
  const [inputTimeValue, setinputTimeValue] = useState('');
  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const onDrop = useCallback((acceptedFiles) => {
  //   setSelectedFiles(acceptedFiles);
  // }, []);
  //const [file, setFile] = useState(null);

  // const handleChange = (file) => {
  //   setFile(file);
  //   props.setFoodGalleryData(file);
  //   setSelectedImage(file);
  //   console.log("image",file);
  // };

  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  

  const handleCategoryClick = (category) => {
    console.log("describe food",category)
    setActiveCategory(category);
    props.setFoodLogData(category);
  };
  
  const handleInputChange = (event) => {
    event.preventDefault();
    if(event.target.name === "inputDescribe"){
      setInputValue(event.target.value);
      props.setFoodNameData(event.target.value);
    }else{
      setinputTimeValue(event.target.value);
      props.setFoodTimeData(event.target.value);
    }
  };


  const handleImageSelect = (e) => {
    console.log("e",e.target.files[0]);
    const file = e.target.files[0];
    //const imageUrl = URL.createObjectURL(file);
    props.setFoodGalleryData(file);
    //setSelectedImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    console.log("image",file);
    
  };
  // const startCamera = async () => {
  //   try {
  //     const constraints = {
  //       video: {
  //         facingMode: "environment"  // Try to access the rear camera
  //       }
  //     };
  //     const stream = await navigator.mediaDevices.getUserMedia(constraints);
  //     videoRef.current.srcObject = stream;
  //   } catch (error) {
  //     console.error('Error accessing the camera:', error);
  //     if (error.name === 'OverconstrainedError') {
  //       // Handle overconstrained error by retrying with different constraints
  //       const fallbackConstraints = {
  //         facingMode: "ture" // Try to access any available camera
  //       }; 
  //       try {
  //         const stream = await navigator.mediaDevices.getUserMedia(fallbackConstraints);
  //         videoRef.current.srcObject = stream;
  //       } catch (fallbackError) {
  //         console.error('Fallback error accessing the camera:', fallbackError);
  //         // Handle fallback error gracefully
  //       }
  //     }
  //   }
  // };

  // const takePhoto = () => {
  //   const context = canvasRef.current.getContext('2d');
  //   context.drawImage(videoRef.current, 0, 0, 640, 480);
  //   const photoURL = canvasRef.current.toDataURL('image/jpeg');
  //   // Use the photoURL or send it to a server for further processing
  //   console.log('photo',photoURL);
  //   props.setFoodCameraData(photoURL);
  //   stopCamera();
  // };

  // const stopCamera = () => {
  //   if (stream) {
  //     stream.getTracks().forEach(track => track.stop());
  //     setStream(null);
  //   }
  // };

  return (
    <section className="FoodLogEntry">
      <div className="container">
        <div className='row'>
        <div className={`col text-center ${activeCategory === 'breakfast' ? 'active' : ''}`} onClick={() => handleCategoryClick('breakfast')}>
                <img src={require(`../images/${activeCategory === 'breakfast' ? 'breakfast-a.png' : 'breakfast.png'}`)} className="img-fluid foodlog-icon" alt="" />
                <h4 className={`FoodLog-title ${activeCategory === 'breakfast' ? 'active' : ''}`}>Breakfast</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'lunch' ? 'active' : ''}`} onClick={() => handleCategoryClick('lunch')}>
                <img src={require(`../images/${activeCategory === 'lunch' ? 'lunch-a.png' : 'lunch.png'}`)} className="img-fluid foodlog-icon" alt="" />
                <h4 className={`FoodLog-title ${activeCategory === 'lunch' ? 'active' : ''}`}>Lunch</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'dinner' ? 'active' : ''}`} onClick={() => handleCategoryClick('dinner')}>
                <img src={require(`../images/${activeCategory === 'dinner' ? 'dinner-a.png' : 'dinner.png'}`)} className="img-fluid foodlog-icon" alt="" />
                <h4 className={`FoodLog-title ${activeCategory === 'dinner' ? 'active' : ''}`}>Dinner</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'snacks' ? 'active' : ''}`} onClick={() => handleCategoryClick('snacks')}>
                <img src={require(`../images/${activeCategory === 'snacks' ? 'snacks-a.png' : 'snacks.png'}`)} className="img-fluid foodlog-icon" alt="" />
                <h4 className={`FoodLog-title ${activeCategory === 'snacks' ? 'active' : ''}`}>Snacks</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'skipped' ? 'active' : ''} border-start`} onClick={() => handleCategoryClick('skipped')}>
                <img src={require(`../images/${activeCategory === 'skipped' ? 'skipped-a.png' : 'skipped.png'}`)} className="img-fluid foodlog-icon" alt="" />
                <h4 className={`FoodLog-title ${activeCategory === 'skipped' ? 'active' : ''}`}>I SKIPPED</h4>
            </div>
        </div>
        
        <div className='row mt-3'>
            <div className='col'>
                <label htmlFor='inputDescribe' className="form-label">Describe your Snacks</label>
                <input type="text" className="form-control" name="inputDescribe" id="inputDescribe" value={inputValue} placeholder="Enter Describe" onChange={handleInputChange}/>
            </div>
            <div className='col-auto px-0 pt-3'>
                <div className=' input-icons'>
                  <label htmlFor='inputTime' className="form-label"></label>
                  <input
                      autoComplete="off"
                      name="problemArrivalTime"
                      disable={true}
                      type="time"
                      defaultValue="10:12"
                      className="form-control"
                      id="inputTime"
                      onChange={handleInputChange}
                    
                  />
                  <i className="bi bi-clock" htmlFor='inputTime' ></i>
                </div>
            </div>
        </div>

        <div className='row mt-3'>
          {/* <div className='col-6 my-3 border-end text-center'>
            <video ref={videoRef} autoPlay />
            <button onClick={startCamera} type="button" className="btn">
              <img src={require('../images/camera.png')} className="img-fluid upload-icon" alt="" />
              <h4 className='upload-title'>Camera</h4>
            </button>
            <button onClick={takePhoto}>Take Photo</button>
          <canvas ref={canvasRef} style={{ display: 'none' }} width={640} height={480} />
          <br/><br/><br/><br/><br/><br/>
          </div> */}
          <div className='col-12 text-center '>
          {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> */}
                  <div className=' input-icons text-center mb-0'>
                    
                    <input type="file" ref={fileInputRef} onChange={handleImageSelect} />

                    <div type="button" className="btn d-flex justify-content-center align-items-center" style={{flexDirection: 'column'}} >
                      <img src={require('../images/gallery.png')} className="img-fluid upload-icon" alt="" />
                      <h4 className='upload-title'>Upload Picture</h4>
                    </div>
                  </div>
                    
                    {selectedImage && (
                      <div className='row mb-5 pb-4'>
                        <div className='col-12'>
                          <hr className='mb-3'/>
                          <img src={selectedImage} alt="Selected" className='customImage' />
                        </div>
                      </div>
                    )}
     </div>
            </div>
        </div>
       
    </section>
  );
}

export default FoodLogEntry;
