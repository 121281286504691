import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './PastCard.css';


function PastCard() {

    
  return (
    <>
   <motion.div
        initial={{ opacity: 0, y: 20  }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className='PastCard'
    >
        <div className='card bg-past-card mb-3'>
                <div className='row'>
                    <div class="col">
                        <h1 className='past-title mb-1'>5th - Care Navigator</h1>
                        <h1 className='past-subtitle mb-0'>Fri, 25 Feb 2025 at 02:30 PM</h1>
                        <h1 className='past-subtitle mb-0'>Amount - Unpaid, Status - <span>Attended</span></h1>
                    </div>
                    <div class="col-auto ps-0">
                        <button type="button" className="btn view-all p-0">View notes</button>
                    </div>
                </div>
        </div>

        <div className='card bg-past-card mb-3'>
                <div className='row'>
                    <div class="col">
                        <h1 className='past-title mb-1'>4th - Podiatrist consultation</h1>
                        <h1 className='past-subtitle mb-0'>Fri, 25 Feb 2025 at 02:30 PM</h1>
                        <h1 className='past-subtitle mb-0'>Amount - Unpaid, Status - <span>Attended</span></h1>
                    </div>
                    <div class="col-auto ps-0">
                        <button type="button" className="btn view-all p-0">View notes</button>
                    </div>
                </div>
        </div>

        <div className='card bg-past-card mb-3'>
                <div className='row'>
                    <div class="col">
                        <h1 className='past-title mb-1'>4th - Telemedicine consultation</h1>
                        <h1 className='past-subtitle mb-0'>Fri, 25 Feb 2025 at 02:30 PM</h1>
                        <h1 className='past-subtitle mb-0'>Amount - Unpaid, Status - <span>Attended</span></h1>
                    </div>
                    <div class="col-auto ps-0">
                        <button type="button" className="btn view-all p-0">View notes</button>
                    </div>
                </div>
        </div>

        
        
    </motion.div>

    </>
  );
}

export default PastCard;
