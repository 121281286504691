import React,{useState,useEffect} from 'react';
import './RecommendedReads.css';
import { recommended_blog_list } from '../../../services/Services';

function RecommendedReads() {
    const [readData, setReadData] = useState([]);
    const uid = localStorage.getItem("uid");
    useEffect(() => {
        fetchData();
    },[]);

    const fetchData =async()=>{
        try {
      
            const response = await recommended_blog_list(uid);
            console.log("Recommended Read", response.data);
            setReadData(response.data.data);
        } catch (error) {
            console.error(error);
        }

    }

    // const openInNewTab = (url) => {
    //     const isMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    //     if (isMobile) {
    //       window.location.href = url;
    //     } else {
    //       const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    //       if (newWindow) newWindow.opener = null;
    //     }
    // };
    const handleClick = (blogUrl,blogTitle) => {
        // Create the JSON object
        const jsonData = {
          type:'open_tab',
          title: blogTitle,
          url: blogUrl
        };
    
        // Convert the JSON object to a string
        const jsonString = JSON.stringify(jsonData);
    
        if (window.Android && window.Android.sendJsonData) {
            window.Android.sendJsonData(jsonString);
          }
          // For iOS
          else if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.sendJsonData
          ) {
            window.webkit.messageHandlers.sendJsonData.postMessage(jsonString);
          }
      };
  return (
    <>
        <section className='RecommendedReads mt-4'>
            <div className='container'>
                <div className='row align-items-center mb-2'>
                <div className='col'>
                        <h1 className='Reads-title'>Recommended Reads</h1>
                    </div>
                    <div className='col-auto'>
                        <button type="button" class="btn view-all">View All</button>
                    </div>
                </div>
                <div className='row align-items-center'>
                {readData.map((item, index) => (
                    <div className='col-6 px-2' key={index}>
                        <div className='RecommendedReads-box'>
                            <div className='box-gredient' onClick={() => handleClick(item.blogUrl,item.title)}>
                                <h2 className='blog-box-title mb-2'>{item.title} </h2>
                                <h2 className='blog-box-title mb-1'><i class='bx bx-show-alt' style={{verticalAlign:'middle'}}></i> <span>34610 Reads</span></h2>
                            </div>
                            <img src={item.imgageUrl} className="blogs-img" alt="" />
                        </div>
                    </div>
                    ))}
                    
                    {/* <div className='col-6 px-2'>
                        <div className='RecommendedReads-box'>
                            <div className='box-gredient'>
                                <h2 className='blog-box-title mb-2'>Taking Ownership Of Diabetes Through Education and Awareness</h2>
                                <h2 className='blog-box-title mb-1'><i class='bx bx-show-alt' style={{verticalAlign:'middle'}}></i> <span>34610 Reads</span></h2>
                            </div>
                            <img src="https://goqii.com/blog/wp-content/uploads/hand-holding-blood-glucose-meter-measuring-blood-sugar-background-is-stethoscope-chart-file.jpg" className="blogs-img" alt="" />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>

    </>
  );
}

export default RecommendedReads;
