import React from 'react';
import './StatsSleep.css';

const StatsSleep = ({statData}) => {
    console.log("statData",statData);

  return (
    <>
        <section className='StatsSleep'>
                <div className='card bg-main-StatsSleep-card'>
                    <div className='card-body p-2'>

                        {/* <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSleep-sub-title'>Avg Deep Sleep</h2>
                            </div>
                            <div className='col-6'>
                                <h2 className='StatsSleep-content-title'>1<span> hr</span> 00<span> mins</span></h2>
                            </div>
                        </div> */}

                        {/* <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSleep-sub-title'>Avg Light Sleep</h2>
                            </div>
                            <div className='col-6'>
                            <h2 className='StatsSleep-content-title'>1<span> hr</span> 00<span> mins</span></h2>
                            </div>
                        </div> */}

                        {/* <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSleep-sub-title'>Avg REM</h2>
                            </div>
                            <div className='col-6'>
                            <h2 className='StatsSleep-content-title'>1<span> hr</span> 00<span> mins</span></h2>
                            </div>
                        </div> */}

                        {/* <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSleep-sub-title'>Avg Restless</h2>
                            </div>
                            <div className='col-6'>
                            <h2 className='StatsSleep-content-title'>1<span> hr</span> 00<span> mins</span></h2>
                            </div>
                        </div> */}
                        <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSleep-sub-title'>Bed Time</h2>
                            </div>
                            <div className='col-6'>
                            <h2 className='StatsSleep-content-title'>11:00<span> pm</span></h2>
                            </div>
                        </div>

                        <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSleep-sub-title'>Wakeup Time</h2>
                            </div>
                            <div className='col-6'>
                            <h2 className='StatsSleep-content-title'>05:00<span> am</span></h2>
                            </div>
                        </div>

                        <div className='row align-items-center border-bottom-line'>
                            <div className='col-6'>
                                <h2 className='StatsSleep-sub-title'>Total Sleep</h2>
                            </div>
                            <div className='col-6'>
                            <h2 className='StatsSleep-content-title'>6<span> hr</span> 00<span> mins</span></h2>
                            </div>
                        </div>

                    </div>
                </div>
           
        </section>        
    </>
  );
};

export default StatsSleep;