import React from 'react';
import HomePage from '../HomePage/HomePage';
import Arena from '../ArenaPage/Arena';
import Play from '../PlayPage/Play';
import HealthLocker from '../HealthLocker/HealthLocker';
import { useLocation } from 'react-router-dom';

const MainPage = ({ activeTab }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uidParam = searchParams.get('uid');
  localStorage.setItem('uid', uidParam);
  return (
    <>
      {activeTab === 'hometab1' && <HomePage />}
      {activeTab === 'hometab2' && <Play />}
      {activeTab === 'hometab3' && <Arena />}
      {activeTab === 'hometab4' && <HealthLocker />}
    </>
  );
};

export default MainPage;
