import React, { useEffect, useState, useRef } from 'react';
import './UpcomingAppointments.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
function UpcomingAppointments() {
    return (
        <>
            <section className='UpcomingAppointments mt-3'>
                <div className='container'>
                    <div className='row align-items-center mb-2'>
                    <div className='col'>
                            <h1 className='Appointments-title'>Upcoming Appointments</h1>
                        </div>
                        <div className='col-auto'>
                            <Link to="/appointments" type="button" class="btn view-all">View All</Link>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-12'>
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={2}
                            >
                                <SwiperSlide>
                                    <>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20  }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2, duration: 0.5 }}
                                        className='UpcomingAppointments-box'
                                        >
                                        <div className='Appointments-img-box'>
                                            <h2 className='Appointments-main-title mb-2'>Care Navigator</h2>
                                            <h3 className='Appointments-main-subtitle'>By Amaira Shaikh</h3>
                                        </div>
                                        <div className='Appointments-box-gredient px-3 py-2'>
                                            <h4 className='Appointments-box-date mb-1'>Date : 22 Aug 2024</h4>
                                            <h4 className='Appointments-box-date'>Time : 02:30 pm</h4>
                                        </div>
                                    </motion.div>
                                    </>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <>
                                    <motion.div 
                                        initial={{ opacity: 0, y: 20  }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.3, duration: 0.5 }}
                                        className='UpcomingAppointments-box'
                                        >
                                        <div className='Appointments-img-box'>
                                            <h2 className='Appointments-main-title mb-2'>Telemedicine Consultation</h2>
                                            <h3 className='Appointments-main-subtitle'>By Dr. Khalida Azim</h3>
                                        </div>
                                        <div className='Appointments-box-gredient px-3 py-2'>
                                            <h4 className='Appointments-box-date mb-1'>Date : 24 Aug 2024</h4>
                                            <h4 className='Appointments-box-date'>Time : 11:30 am</h4>
                                        </div>
                                    </motion.div>
                                    </>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <>
                                    <motion.div 
                                        initial={{ opacity: 0, y: 20  }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.4, duration: 0.5 }}
                                        className='UpcomingAppointments-box'
                                        >
                                        <div className='Appointments-img-box'>
                                            <h2 className='Appointments-main-title mb-2'>Care Navigator</h2>
                                            <h3 className='Appointments-main-subtitle'>By Amaira Shaikh</h3>
                                        </div>
                                        <div className='Appointments-box-gredient px-3 py-2'>
                                            <h4 className='Appointments-box-date mb-1'>Date : 25 Aug 2024</h4>
                                            <h4 className='Appointments-box-date'>Time : 02:30 pm</h4>
                                        </div>
                                    </motion.div>
                                    </>
                                </SwiperSlide>
                            </Swiper>
                         </div>                 
                    </div>
                           
                </div>
            </section>
        </>
    );
}

export default UpcomingAppointments;
    