import React from 'react';
import { motion } from 'framer-motion';

function HRAQ3({ updateHraData, hraData }) {
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const newHealthConditions = checked
      ? [...hraData.healthConditions, value]
      : hraData.healthConditions.filter((condition) => condition !== value);

    updateHraData('healthConditions', newHealthConditions);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      className=''
    >
      <div className='row'>
        <div className="col-12 mb-3">
          <h1 className='hra-title'>Do you have any health conditions?</h1>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes2"
              value="None"
              name=""
            //   checked={hraData.healthConditions.includes('None')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes2' className="form-check-label form-label-input mt-[6px]">None</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes3"
              value="Diabetes"
            //   checked={hraData.healthConditions.includes('Diabetes')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes3' className="form-check-label form-label-input mt-[6px]">Diabetes</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes4"
              value="Hypercholesterolemia"
            //   checked={hraData.healthConditions.includes('Hypercholesterolemia')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes4' className="form-check-label form-label-input mt-[6px]">Hypercholesterolemia</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes5"
              value="Hypertension"
            //   checked={hraData.healthConditions.includes('Hypertension')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes5' className="form-check-label form-label-input mt-[6px]">Hypertension</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes6"
              value="Heart Disease"
            //   checked={hraData.healthConditions.includes('Heart Disease')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes6' className="form-check-label form-label-input mt-[6px]">Heart Disease</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes7"
              value="Poly Cystic Ovarian Disease"
            //   checked={hraData.healthConditions.includes('Poly Cystic Ovarian Disease')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes7' className="form-check-label form-label-input mt-[6px]">Poly Cystic Ovarian Disease</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes8"
              value="Others"
            //   checked={hraData.healthConditions.includes('Others')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes8' className="form-check-label form-label-input mt-[6px]">Others</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label htmlFor='hraSub-2' className="form-label form-label-input">Specify</label>
          <textarea
            className="form-control control-input-ui"
            id="hraSub-2"
            placeholder="Specify"
            rows="3"
            value={hraData.hraSpecify}
            onChange={(e) => updateHraData('hraSpecify', e.target.value)}
          ></textarea>
        </div>
        <div className="col-12 mb-3 mt-4">
          <h1 className='hra-title'>Does a history of diabetes exist within your family?</h1>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="checkbox"
              className="form-check-input form-check-input-ui"
              id="hraCheckboxes1"
              value="It runs in my family"
            //   checked={hraData.healthConditions.includes('It runs in my family')}
              onChange={handleCheckboxChange}
            />
            <label htmlFor='hraCheckboxes1' className="form-check-label form-label-input mt-[6px]">It runs in my family</label>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default HRAQ3;
