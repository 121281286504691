import React,{useState} from 'react';
import SleepDetailGraph from './component/SleepDetailGraph';
import StatsSleep from './component/StatsSleep';
import './SleepDetail.css';
import { motion } from 'framer-motion';



const SleepDetail = () => {

  const [statData,setStatData] = useState(0);
  const handleStatCallback = (childData) => {
    setStatData(childData);
  };

  return (
    <>
      <motion.section
       className='SleepDetail mb-3'
       initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.1 }}
       >
          <div className='row mt-3'>
            <div className='col-12'>
              <h1 className='sleep-main-title mb-2'>Graph</h1>
            </div>
              <div className='col-12'>
                <motion.div
                      initial={{ opacity: 0, y: 20  }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                      className='bg-sleep-card'
                      >
                    <SleepDetailGraph activity={'sleep-detail'} parentCallback={handleStatCallback}/>
                </motion.div>
              </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <h1 className='sleep-main-title mb-2'>Stats</h1>
            </div>
              <div className='col-12'>
                <motion.div
                      initial={{ opacity: 0, y: 20  }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4, duration: 0.5 }}
                      >
                  <StatsSleep statData = {statData}/>
                </motion.div>
              </div>
          </div>
          
      </motion.section>
    </>
  );
};

export default SleepDetail;