import React from 'react';
import { motion } from 'framer-motion';
import WhatsIncluded from './component/WhatsIncluded';

import './JoinDiabeticWellnessPlan.css';


function JoinDiabeticWellnessPlan() {
  return (
    <>
    <motion.section 
        className="JoinDiabeticWellnessPlan mt-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >

        <div className='container'>
          
          <div className='row'>
              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        >
                        <img src={require("../../images/img-whats-banner.png")} className='img-fluid' alt="" />
                    </motion.div>
              </div>
              
              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        >
                        <WhatsIncluded/>
                    </motion.div>
              </div>
          </div>
        </div>
        
    </motion.section>

    </>
  );
}

export default JoinDiabeticWellnessPlan;
