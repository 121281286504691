import React from 'react';

import WaterDetailGraph from './component/WaterDetailGraph';
import StatsWater from './component/StatsWater';
import './WaterDetail.css';
import { motion } from 'framer-motion';



const WaterDetail = () => {
  return (
    <>
      <motion.section
       className='WaterDetail mb-3'
       initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.1 }}
       >
          <div className='row mt-3'>
            <div className='col-12'>
              <h1 className='water-main-title mb-2'>Graph</h1>
            </div>
              <div className='col-12'>
                <motion.div
                      initial={{ opacity: 0, y: 20  }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                      className='bg-water-card'
                      >
                    <WaterDetailGraph activity={'water-detail'}/>
                </motion.div>
              </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <h1 className='water-main-title mb-2'>Stats</h1>
            </div>
              <div className='col-12'>
                <motion.div
                      initial={{ opacity: 0, y: 20  }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4, duration: 0.5 }}
                      >
                  <StatsWater/>
                </motion.div>
              </div>
          </div>
          
      </motion.section>
    </>
  );
};

export default WaterDetail;