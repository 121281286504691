import React,{useState} from 'react';
import { Helmet } from 'react-helmet';
import ActivityLogEntry from "../component/ActivityLogEntry";
import CalendarView from "../component/CalendarView";
import './ActivityLog.css';


function ActivityLog() {
  const [calendarData, setCalendarData] = useState([]);

  //select calendar 
  const activityCalendarLog= (data) => {
    setCalendarData(data);
    console.log('parent Calendar Log',data)
  };
  
  return (
    <>
        <Helmet>
        <title>Activity Log</title>
      </Helmet>
        <CalendarView setCalendarData = {activityCalendarLog}/>
        <ActivityLogEntry calendarData = {calendarData}/>
    </>
  );
}

export default ActivityLog;
