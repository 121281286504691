import React,{useState} from 'react';
import './CoachRatingCard.css';
import StarRating from './StarRating';
import { submit_user_carenavigator_feedback } from '../../../services/Services';
import { useNavigate } from 'react-router-dom';


const CoachRatingCard = ({readData}) => {
    console.log("readData",readData);
    const [numberStars,setNumberStars] = useState(0);
    const [feedback, setFeedback] = useState('');
    const navigate = useNavigate();
    const handleCallMadeCallback = (childData) => {
        setNumberStars(childData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        console.log('Submitted feedback:', feedback);
        console.log('Submitted feedback:', readData.memberShipNo);
        try {
            let response = await submit_user_carenavigator_feedback(readData.memberShipNo,readData.careNavigatorId,feedback,numberStars);
            if(response.data.code === 200){
                //navigate(response.data.data.redirectUrl);
                const jsonData = {
                    type:'only_dismiss',
                   dismissScreen:true
                  };
              
                  // Convert the JSON object to a string
                  const jsonString = JSON.stringify(jsonData);
              
                  if (window.Android && window.Android.sendJsonData) {
                    window.Android.sendJsonData(jsonString);
                  }
                  // For iOS
                  else if (
                    window.webkit &&
                    window.webkit.messageHandlers &&
                    window.webkit.messageHandlers.sendJsonData
                  ) {
                    window.webkit.messageHandlers.sendJsonData.postMessage(jsonString);
                  }        
            }
        } catch (error) {
            console.log(error);
        }

    }
  return (
    <>
        <section className='CoachRatingCard'>
                <div className='card bg-main-CoachRating-card'>
                    <div className='card-body p-2'>
                        <form onSubmit={handleSubmit}>
                            <div className='row align-items-center'>
                                <div className='col-12 text-center'>
                                    <h1 className='CoachRating-main-title mb-3'>Rate Consultation Experience</h1>
                                </div>
                                <div className='col-12 text-center'>
                                    <StarRating parentCallback={handleCallMadeCallback} />
                                </div>

                                <div className='col-12 text-center mt-4'>
                                    <h1 className='CoachRating-main-title mb-3'>Write a Feedback</h1>
                                </div>
                                <div className='col-12 text-center'>
                                    <textarea className="form-control input-field-ui" placeholder='Feedback Description' rows="3" value={feedback}
            onChange={(e) => setFeedback(e.target.value)}></textarea>
                                </div>
                                <div className='col-12 text-center'>
                                    <button type="submit" className='btn btn-join-new px-4 mt-2'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
           
        </section>        
    </>
  );
};

export default CoachRatingCard;