import React,{useState} from 'react';
import CalendarView from '../component/CalendarView';
import SleepLogEntry from "../component/SleepLogEntry";
import { Helmet } from 'react-helmet';
import './SleepLog.css';


function SleepLog() {
  const [calendarData, setCalendarData] = useState([]);

  //select calendar 
  const sleepCalendarLog= (data) => {
    setCalendarData(data);
    console.log('parent Calendar Log',data)
  };

  return (
    <>
      <Helmet>
        <title>Sleep Log</title>
      </Helmet>
      <CalendarView setCalendarData = {sleepCalendarLog} />
      <SleepLogEntry calendarData = {calendarData}/>
    </>
  );
}

export default SleepLog;
