
import React, { useState, useEffect }  from "react";
import { useLocation } from 'react-router-dom';
import HabitSubMenu from "./HabitSubMenu";
import SleepGraph from "../charts/SleepGraph";
import { sleep_log_data } from "../services/Services";

const Sleep =({activity})=>{
    console.log("sleep",activity);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const uid = searchParams.get("goqiiUserId");
    const uuid = searchParams.get('uuid');
    const tab = searchParams.get("tab");
    const [sleepData, setSleepData] = useState([]);
    const [typeData, setTypeData] = useState(type);
    let currentDate = new Date();
    let formattedDate, formattedStartDate,formattedEndDate;

    const fetchData = async (userId,sdate,edate) => {
        try {
            console.log("uid",userId);
            console.log("sdate",sdate);
            console.log("edate",edate);
            const response = await sleep_log_data(userId,sdate,edate,type);
            console.log("response sleepData", response.data.data);
            setSleepData(response.data.data);
            setTypeData(type);

        } catch (error) {
            console.error(error);
        }
    };
    
//     useEffect(() => {
//         if(type === 'weekly'){
//             console.log('useeffect',type)
//             const data = [
//                 {
//                     "date": "Mon",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "Tue",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "Wed",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "Thu",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
                
//                 {
//                     "date": "Fri",
//                     "deepSleep": 3,
//                     "lightSleep": 4,
//                     "remSleep": 1,
//                     "awakeSleep": 1
//                 },
                
//                 {
//                     "date": "Sat",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//                 {
//                     "date": "Sun",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//             ];
//             setSleepData(data);
//             setTypeData(type);
//         }else if(type === 'daily'){
//             const data = [
//                 {
//                     "date": "2024-02-28",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
                
//                 {
//                     "date": "2024-02-29",
//                     "deepSleep": 3,
//                     "lightSleep": 4,
//                     "remSleep": 1,
//                     "awakeSleep": 1
//                 },];
//             setSleepData(data);
//             setTypeData(type);
//         }else if(type === 'monthly'){
//             const data = [
//                 {
//                     "date": "1st W",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "2nd W",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "3rd W",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "4th W",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
                
//                 {
//                     "date": "5th W",
//                     "deepSleep": 3,
//                     "lightSleep": 4,
//                     "remSleep": 1,
//                     "awakeSleep": 1
//                 },
                
                
//                 // and so on for each day of the month
//             ];
//             setSleepData(data);
//             setTypeData(type);
//         }else if(type === 'yearly'){
//             const data = [
//                 {
//                     "date": "Jan",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "Feb",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "Mar",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "Apr",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
                
//                 {
//                     "date": "May",
//                     "deepSleep": 3,
//                     "lightSleep": 4,
//                     "remSleep": 1,
//                     "awakeSleep": 1
//                 },
                
//                 {
//                     "date": "Jun",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//                 {
//                     "date": "Jul",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//                 {
//                     "date": "Aug",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },

//                 {
//                     "date": "Sept",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//                 {
//                     "date": "Oct",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//                 {
//                     "date": "Nov",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//                 {
//                     "date": "Dec",
//                     "deepSleep": 5,
//                     "lightSleep": 4,
//                     "remSleep": 2,
//                     "awakeSleep": 1
//                 },
//             ];
//             setSleepData(data);
//             setTypeData(type);
//         }
//   },[type]);
useEffect(() => {
    if(type === 'daily'){
        const previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate());
        formattedStartDate = previousDate.toISOString().slice(0, 10);
        formattedEndDate = currentDate.toISOString().split('T')[0];
        fetchData({uid},{formattedStartDate},{formattedEndDate});

    }else if(type === 'weekly'){
        const startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - startDate.getDay()+1);
        const endDate = new Date(currentDate);
        endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
        formattedStartDate = startDate.toISOString().slice(0, 10);
        formattedEndDate = endDate.toISOString().slice(0, 10);
        fetchData({uid},{formattedStartDate},{formattedEndDate});

    }else if(type === 'monthly'){
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        fetchData({uid},{formattedStartDate},{formattedEndDate}); 
    }else if(type === 'yearly'){
        const startDate = new Date(currentDate.getFullYear(), 0, 2);
        const endDate = new Date(currentDate.getFullYear(), 12, 1);
        formattedStartDate = startDate.toISOString().split('T')[0];
        formattedEndDate = endDate.toISOString().split('T')[0];
        fetchData({uid},{formattedStartDate},{formattedEndDate}); 
    }else{
        const previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate() - 1);
        formattedStartDate = previousDate.toISOString().slice(0, 10);
        formattedEndDate = currentDate.toISOString().split('T')[0];
        fetchData({uid},{formattedStartDate},{formattedEndDate});
    }
},[type]);

    return(
        <>
            <HabitSubMenu activity={activity} uid={uid} startDate= {formattedDate} endDate={formattedDate} tab={tab} uuid={uuid}/>
            <div className="container contentbox">
                <div className="row">
                    <div className="col-12 px-0">
                        {sleepData ? (
                            <SleepGraph sleepData={sleepData} type={typeData} />
                        ) : (
                            <div className="error-message">
                                <p>No records found. User has not synced data</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Sleep
