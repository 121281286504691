import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PieChartWithProgress = ({totalPieValue}) => {
  const config = {
    chart: {
      type: 'pie',
      height: 100,
      tooltip: false,
      margin: [0, 0, 0, 0], // Set margin to [top, right, bottom, left]
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: `<h5 class="log-pie-title">${totalPieValue}</h5>`,
      align: 'center',
      verticalAlign: 'middle',
      style: {
        textAlign: 'center',
      },
      x: 0,
      y: 13,
      useHTML: true,
    },
    plotOptions: {
      pie: {
        enableMouseTracking: false, // Disable hover effect
      },
    },
    series: [
      {
        type: 'pie',
        name: 'Progress',
        innerSize: '78%',
        dataLabels: {
          enabled: false,
        },
        data: [
          { name: 'Completed', y: 60, color: '#92BDE0' },
          { name: 'Remaining', y: 40, color: '#E5E5E5' },
        ],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={config} />;
};

export default PieChartWithProgress;
