import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './HRAQuestionnaire.css';

import HRAQ1 from './component/HRAQ1';
import HRAQ2 from './component/HRAQ2';
import HRAQ3 from './component/HRAQ3';
import HRAQ4 from './component/HRAQ4';
import HRAQ5 from './component/HRAQ5';
import HRAQ6 from './component/HRAQ6';
import HRAViewReport from './component/HRAViewReport';
import { Navigate } from 'react-router-dom';

function HRAQuestionnaire() {
  
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showReport, setShowReport] = useState(false);
  const [hraData, setHraData] = useState({
    weight: '',
    height: '',
    goal: '',
    weightLossHistory: '',
    weightSpecify: '',
    hraSpecify:'',
    healthConditions:[],
    waterIntake:'',
    exerciseRadios:'',
    foodConditions:[],
    foodSpecify:''
    // Add other question-related states here
  });

  const updateHraData = (key, value) => {
    setHraData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };

  const questions = [
    <HRAQ1 key="q1" updateHraData={updateHraData} hraData={hraData} />,
    <HRAQ2 key="q2" updateHraData={updateHraData} hraData={hraData} />,
    <HRAQ3 key="q3" updateHraData={updateHraData} hraData={hraData} />,
    <HRAQ4 key="q4" updateHraData={updateHraData} hraData={hraData} />,
    <HRAQ5 key="q5" updateHraData={updateHraData} hraData={hraData} />,
    <HRAQ6 key="q6" updateHraData={updateHraData} hraData={hraData} />,
  ];

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSaveHRA = () => {
    setShowReport(true);
    console.log('Final HRA Data:', hraData);
    
  };

  return (
    <>
      {!showReport ? (
        <motion.section 
          className="HRAQuestionnaire mt-4 mb-5"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.5 }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-12 mb-3'>
                {questions[currentQuestion]}
              </div>
            </div>
          </div>
        </motion.section>
      ) : (
        <HRAViewReport />
      )}

      {!showReport && (
        <div className='bottom-fixed-button px-3'>
          <div className='row'>
            {currentQuestion > 0 && (
              <div className='col-6 text-center'>
                <button className="btn btn-hra-back" onClick={handleBack}>Back</button>
              </div>
            )}
            {currentQuestion < questions.length - 1 ? (
              <div className={`col-${currentQuestion > 0 ? '6' : '12'} text-center`}>
                <button className="btn btn-hra-next" onClick={handleNext}>Next</button>
              </div>
            ) : (
              <div className='col-6 text-center'>
                <button className="btn btn-hra-next" onClick={handleSaveHRA}>Save HRA</button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default HRAQuestionnaire;
