import React from 'react';
import { motion } from 'framer-motion';
import './UpcomingCard.css';


function UpcomingCard() {

    
  return (
    <>
   <motion.div
        initial={{ opacity: 0, y: 20  }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className='UpcomingCard'
    >
        <div className='card bg-appointment-card mb-3'>
                <div className='row align-items-center'>
                    <div class="col">
                        <h1 className='upcoming-title mb-1'>5th - Lab Tests</h1>
                        <h1 className='upcoming-subtitle mb-0'>Fri, 25 Feb 2025 at 02:30 PM</h1>
                    </div>
                    {/* <div class="col-4 ps-0">
                        <button type="button" className="btn btn-paynow">Pay Now</button>
                    </div> */}
                </div>
        </div>

        <div className='card bg-appointment-card mb-3'>
                <div className='row align-items-center'>
                    <div class="col">
                        <h1 className='upcoming-title mb-1'>5th - Telemedicine consultation</h1>
                        <h1 className='upcoming-subtitle mb-0'>Fri, 25 Feb 2025 at 02:30 PM</h1>
                    </div>
                    {/* <div class="col-4 ps-0">
                        <button type="button" className="btn btn-paynow">Pay Now</button>
                    </div> */}
                </div>
        </div>
        
    </motion.div>

    </>
  );
}

export default UpcomingCard;
