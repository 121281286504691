import React from 'react';
import StepsDetailGraph from './component/StepsDetailGraph';
import StatsSteps from './component/StatsSteps';
import './StepsDetail.css';
import { motion } from 'framer-motion';



const StepsDetail = () => {
  return (
    <>
      <motion.section
       className='StepsDetail mb-3'
       initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.1 }}
       >
          <div className='row mt-3'>
            <div className='col-12'>
              <h1 className='steps-main-title mb-2'>Graph</h1>
            </div>
              <div className='col-12'>
                <motion.div
                      initial={{ opacity: 0, y: 20  }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                      className='bg-steps-card'
                      >
                    <StepsDetailGraph activity={'steps-detail'}/>
                </motion.div>
              </div>
          </div>

          <div className='row mt-4'>
            <div className='col-12'>
              <h1 className='steps-main-title mb-2'>Stats</h1>
            </div>
              <div className='col-12'>
                <motion.div
                      initial={{ opacity: 0, y: 20  }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4, duration: 0.5 }}
                      >
                  <StatsSteps/>
                </motion.div>
              </div>
          </div>
          
      </motion.section>
    </>
  );
};

export default StepsDetail;