import React, { useState, useEffect } from 'react';
import NotificationToast from '../component/NotificationToast';
import './SleepLogEntry.css';
import { sleep_log_post_data, sleep_list_get_user_data, user_delete_log_data } from '../services/Services';

function SleepLogEntry(props) {
  const [activeCategory, setActiveCategory] = useState('');
  const [errors, setErrors] = useState('');
  const [sleepLogData, setSleepLogData] = useState([]);
  const [sleepLogDataCount, setSleepLogDataCount] = useState(0);
  const [success, setSuccess] = useState('');
  const [toastKey, setToastKey] = useState(0); // State variable for the key
  const [isLoading, setIsLoading] = useState(false); // State variable for loader
  const [disable, setDisable] = useState(true);
  
  
  const defaultTimeSlept = '22:00'; // 10:00 PM in 24-hour format
  const [selectedTimeSlept, setSelectedTimeSlept] = useState(defaultTimeSlept);

  const defaultTimeWake = '07:00'; // 7:00 AM in 24-hour format
  const [selectedTimeWake, setSelectedTimeWake] = useState(defaultTimeWake);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const validateTime = (time) => {
    const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(time);
  };

  const submitSleepDataLog=async()=>{ 
    setDisable(false);   
    console.log("final sleep submit",activeCategory,props.calendarData,selectedTimeSlept,selectedTimeWake);
    let bedDate;
    setErrors("");
    setIsLoading(true); // Show loader
    if(props.calendarData.length === 0){
      bedDate = new Date();
      console.log("bedDatesss",bedDate);
    }else{
      bedDate = new Date(props.calendarData);
    }
    
    
    // console.log("bedDate",bedDate);
    // //const sleepInDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    // let sleepInDate = `${bedDate.getDate()}-${bedDate.getMonth() + 1}-${bedDate.getFullYear()} ${selectedTimeSlept}:${bedDate.getSeconds()}`;
    // console.log("sleepInDate",sleepInDate);

    // bedDate.setDate(bedDate.getDate() + 1); 
    // let wakeupDate = `${bedDate.getDate()}-${bedDate.getMonth() + 1}-${bedDate.getFullYear()} ${selectedTimeWake}:${bedDate.getSeconds()}`;
    // console.log("wakeupDate",wakeupDate);
    
    if (!activeCategory) {
      setIsLoading(false); // Hide loader
      setErrors("Please select one sleep quality.");
      setDisable(true);
    } else if (!validateTime(selectedTimeSlept) || !validateTime(selectedTimeWake)) {
      setIsLoading(false); // Hide loader
      setErrors("Please select valid sleep and wake times in 24-hour format.");
      setDisable(true);
    } else {
      let logData = {
        'logDate':props.calendarData,
        'logName':activeCategory,
        'sleepIn':selectedTimeSlept,
        'wakeUp':selectedTimeWake,
      }

      try {
          const response = await sleep_log_post_data(logData);
          if(response.data.code === 200){
            fetchData();
            setSuccess(response.data.data.message);
            setDisable(true);
          }else{
            setErrors(response.data.data.message);
            setDisable(true);
          }
          //sendDataToAndroid();
        } catch (error) {
          console.error(error);
          setErrors("An error occurred while submitting sleep data.");
        }
        setIsLoading(false); // Hide loader
    }
    setToastKey(prevKey => prevKey + 1);
    //console.log("response food data post", response.data);
    //setFoodPostData(response.data.data);
  }

  /*const sendDataToAndroid = () => {
    var dataToSend = {
      type: "close_screen",
      message: "Log Successfully"
    };
    window.Android.sendDataToAndroid(JSON.stringify(dataToSend));
  }*/


const userDeleteLog = async(deleteLogId,type) =>{
  let response;
try {
    var data = {
      type: type,
      deleteLogId: deleteLogId
    };
    console.log("user delete Log",deleteLogId);
    response = await user_delete_log_data(JSON.stringify(data));
    if(response.data.code === 200){
      fetchData();
      setSuccess(response.data.data.message)
    }else{
      setErrors("An error occurred while deleting sleep data.");
    }
  } catch (error) {
      console.error(error);
      setErrors("An error occurred while deleting sleep data.");
  }
  return response;
}

const fetchData = async () => {
  try {
    console.log("fetch data");
      const response = await sleep_list_get_user_data(props.calendarData);
      console.log("response sleep_list_get_user_data", response.data.data);
      setSleepLogData(response.data.data);
      console.log("response.data.data.length",response.data.data.length);
      setSleepLogDataCount(response.data.data.length);
  } catch (error) {
      console.error(error);
      setErrors("An error occurred while fetching sleep log data.");
  }
};

useEffect(() => {

      fetchData();
  },[success,errors,props.calendarData]);

  return (
    <>
      <section className="SleepLogEntry">
        <div className="floating-loader" style={{ display: isLoading ? 'block' : 'none' }}>
          <div className="loader"></div>
          Loading...
        </div>
        <div className="container">
            <div className='row align-items-center'>
                <div className='col-12'>
                    <h1 className='sleep-main-title'>Log New Sleep</h1>
                </div>
                <div className='col-12 mt-3'>
                    <label className='sleep-box' htmlFor='septInput'>
                        <div className='row align-items-center'>
                              <div className='col-auto'>
                                  <img src={require(`../images/icon-sept.png`)} className="sleep-icon-new" alt="" />
                              </div>
                              <div className='col ps-0'>
                                  <h3 className="sleep-box-title ">Slept</h3>
                              </div>
                              <div className='col ps-0 text-end'>
                                  {/* <h3 className="sleep-box-title text-center" type="time"><span>07:00 am</span></h3> */}
                                  <input
                                    type="time"
                                    className="sleep-box-title1 text-center"
                                    id='sleptInput'
                                    defaultValue={defaultTimeSlept}
                                    onChange={(e) => setSelectedTimeSlept(e.target.value)}
                                  />
                              </div>
                        </div>
                    </label>
                    <label className='sleep-box mt-2'>
                        <div className='row align-items-center'>
                              <div className='col-auto'>
                                  <img src={require(`../images/icon-wakeup.png`)} className="sleep-icon-new" alt="" />
                              </div>
                              <div className='col ps-0'>
                                  <h3 className="sleep-box-title ">Wake Up</h3>
                              </div>
                              <div className='col ps-0 text-end'>
                                  {/* <h3 className="sleep-box-title text-center" type="time"><span>07:00 am</span></h3> */}
                                  <input
                                    type="time"
                                    className="sleep-box-title1 text-center"
                                    id='wakeInput'
                                    defaultValue={defaultTimeWake}
                                    onChange={(e) => setSelectedTimeWake(e.target.value)}
                                  />
                              </div>
                        </div>
                    </label>
                </div>
            </div>
            
          <div className='row'>
            <div className='col-12 mt-4'>
                <label className="form-label">Rate your sleep quality</label>
            </div>
            <div className={`col text-center ${activeCategory === 'Terrible' ? 'active' : ''}`} onClick={() => handleCategoryClick('Terrible')}>
                <img src={require(`../images/${activeCategory === 'Terrible' ? 'sleep-terrible-a.png' : 'sleep-terrible.png'}`)} className="img-fluid sleeplog-icon" alt="" />
                <h4 className={`sleeplog-title ${activeCategory === 'Terrible' ? 'active' : ''}`}>Terrible</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'Bad' ? 'active' : ''}`} onClick={() => handleCategoryClick('Bad')}>
                <img src={require(`../images/${activeCategory === 'Bad' ? 'sleep-bad-a.png' : 'sleep-bad.png'}`)} className="img-fluid sleeplog-icon" alt="" />
                <h4 className={`sleeplog-title ${activeCategory === 'Bad' ? 'active' : ''}`}>Bad</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'Ok' ? 'active' : ''}`} onClick={() => handleCategoryClick('Ok')}>
                <img src={require(`../images/${activeCategory === 'Ok' ? 'sleep-ok-a.png' : 'sleep-ok.png'}`)} className="img-fluid sleeplog-icon" alt="" />
                <h4 className={`sleeplog-title ${activeCategory === 'Ok' ? 'active' : ''}`}>Ok</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'Good' ? 'active' : ''}`} onClick={() => handleCategoryClick('Good')}>
                <img src={require(`../images/${activeCategory === 'Good' ? 'sleep-good-a.png' : 'sleep-good.png'}`)} className="img-fluid sleeplog-icon" alt="" />
                <h4 className={`sleeplog-title ${activeCategory === 'Good' ? 'active' : ''}`}>Good</h4>
            </div>

            <div className={`col text-center ${activeCategory === 'Great' ? 'active' : ''}`} onClick={() => handleCategoryClick('Great')}>
                <img src={require(`../images/${activeCategory === 'Great' ? 'sleep-great-a.png' : 'sleep-great.png'}`)} className="img-fluid sleeplog-icon" alt="" />
                <h4 className={`sleeplog-title ${activeCategory === 'Great' ? 'active' : ''}`}>Great</h4>
            </div>

          </div>
          {/* {errors && <NotificationToast message={errors} key={toastKey} type="error"/>} */}
          {success && <NotificationToast message={success} key={toastKey} type="success" />}
          <div className='row mt-5'>
            <div className='col-6'>
              <button type="button" className="btn btn-success back-btn">Cancel</button>
            </div>
            <div className='col-6'>
              <button type="button" className="btn btn-success next-btn" disabled={!disable} onClick={submitSleepDataLog}>Log</button>
            </div>
            <div className='col-12 mt-3'>
            <hr/>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
                <h2 className='sleep-detail-title'>
                Logged sleep
                </h2>
            </div>
            <div className='col-12 px-4'>
            {sleepLogDataCount === 0 ? (
                <div className="text-center">No sleep log found.</div>
              ) : (
                sleepLogData.map(sleep => (
            <div className='row align-items-center py-3 border-bottom' key={sleep.sleepLogId}>
                 <div className='col-auto'>
                    <img src={require(`../images/sleep-log.png`)} className="sleep-icon1" alt="" />
                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label1'>Slept</h3>
                    <h3 className='form-label2'>{sleep.startTime}</h3>
                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label1'>WakeUp</h3>
                    <h3 className='form-label2'>{sleep.awakeTime}</h3>
                 </div>
                 <div className='col'>
                    <h3 className='form-label1'>Total Sleep</h3>
                    <h3 className='form-label2'>{sleep.duration}</h3>
                    <h3 className='form-label2' onClick={(e) => userDeleteLog(sleep.sleepLogId,'sleep')}><i class='bx bx-trash'></i></h3>
                 </div>
                 
            </div>
                ))
              )}
            {/* <div className='row align-items-center py-3 border-bottom'>
                 <div className='col-auto'>
                    <img src={require(`../images/sleep-log.png`)} className="sleep-icon1" alt="" />
                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label1'>Slept</h3>
                    <h3 className='form-label2'>10:00 PM</h3>
                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label1'>WakeUp</h3>
                    <h3 className='form-label2'>7:00 AM</h3>
                 </div>
                 <div className='col'>
                    <h3 className='form-label1'>Logged at</h3>
                    <h3 className='form-label2'>05:04 PM</h3>
                 </div>
            </div> */}
            </div>
        </div>

        </div>
      </section>
    </>
  );
}

export default SleepLogEntry;
