import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const HabitSubMenu = ({ activity, uid, startDate,endDate, tab, uuid,parentCallback }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [activeTab, setActiveTab] = useState("daily"); // State to manage active tab

  const handleTabClick = (tabType) => {
    setActiveTab(tabType);
    //navigate(`/${activity}?type=${tabType}`);
    console.log("tabType",tabType);
    parentCallback(tabType)
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-3 px-0">
            <div className="btn-group graph-tab">
              <button
                className={`btn btn-outline-primary menu-tab ${
                  activeTab === "daily" ? "active-tab" : ""
                }`}
                onClick={() => handleTabClick("daily",{tab})}
              >
                DAILY
              </button>
              <button
                className={`btn btn-outline-primary menu-tab ${
                  activeTab === "weekly" ? "active-tab" : ""
                }`}
                onClick={() => handleTabClick("weekly")}
              >
                WEEKLY
              </button>
              {activity === "spo" || activity === "heartrate" ? (
                <></>
              ) : (
                <>
                  <button
                    className={`btn btn-outline-primary menu-tab ${
                      activeTab === "monthly" ? "active-tab" : ""
                    }`}
                    onClick={() => handleTabClick("monthly")}
                  >
                    MONTHLY
                  </button>
                  <button
                    className={`btn btn-outline-primary menu-tab ${
                      activeTab === "yearly" ? "active-tab" : ""
                    }`}
                    onClick={() => handleTabClick("yearly")}
                  >
                    YEAR
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HabitSubMenu;
