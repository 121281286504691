import React,{useState} from 'react';
import { Helmet } from 'react-helmet';
import WeightLogEntry from "../component/WeightLogEntry";
import './WeightLog.css';
import CalendarView from '../component/CalendarView';


function WeightLog() {
  const [calendarData, setCalendarData] = useState([]);
  const [wightTimeData, setWeightTimeData] = useState([]);

  //select calendar 
  const weightCalendarLog= (data) => {
    setCalendarData(data);
    console.log('parent Calendar Log',data)
  };

  const weightTimeLog= (data) => {
    setWeightTimeData(data);
    console.log('parent time Log',data)
  };
  return (
    <>
     <Helmet>
        <title>Weight Log</title>
      </Helmet>
      <CalendarView setCalendarData = {weightCalendarLog}/>
      <WeightLogEntry setWeightTimeData = {weightTimeLog} calendarData = {calendarData}/>
    </>
  );
}

export default WeightLog;
