import React from 'react';
import { motion } from 'framer-motion';
import HealthLockerCard from './component/HealthLockerCard';

import './HealthLocker.css';


function HealthLocker() {
  return (
    <>
    <motion.section 
        className="HealthLocker mt-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >

        <div className='container'>
          <div className="row align-items-center">
              <div className='col-6 px-1'>
                  <h1 className='maintitle'>Health Locker</h1>
              </div>
              <div className='col-6 px-1'>
                <button className="btn action-button btn-join" data-bs-toggle="modal" data-bs-target="#addNewRecordModal">Add New Record</button>
              </div>
          </div>
          <div className='row'>
              <div className='col-12 mb-3 px-1'>
                     <HealthLockerCard/>
              </div>
          </div>
        </div>
        
    </motion.section>

    <div className="modal fade" id="addNewRecordModal" tabindex="-1" aria-labelledby="addNewRecordModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="addNewRecordModalLabel">Add New Record</h1>
            <button type="button" className="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <div className="row">
              <div className="col-md-12 mb-3">
                <label className="form-label">Report Type <span className="required">*</span></label
                ><select name="reportTypeId" className="form-select select-input-ui">
                  <option value="">Select</option>
                  <option value="1">Lab Reports</option>
                  <option value="2">Prescriptions</option>
                  <option value="3">ECG Reports</option>
                  <option value="4">Sonography Reports</option>
                  <option value="5">Vaccination Records</option>
                  <option value="6">Medical History</option>
                  <option value="7">Allergies</option>
                  <option value="8">Medications</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label">Description</label
                ><textarea
                  type="text"
                  name="title"
                  placeholder="Description"
                  rows="3"
                  className="form-control control-input-ui"
                ></textarea>
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label">Select File <span className="required">*</span></label
                ><input
                  type="file"
                  accept=".pdf,.jpeg,.jpg,.png,.tiff,.doc,.docx,.xls,.xlsx,.csv,.txt,.dcm"
                  className="form-control control-input-ui"
                />
              </div>
              <div className="col-md-12 text-center">
                <div className="hide"></div>
                <button className="btn btn-primary btn-join">Save</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default HealthLocker;
