import React, { useState } from 'react';
import './HealthLockerCard.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';




const HealthLockerCard = () => {
  // State to manage the title of the offcanvas
  const [offcanvasTitle, setOffcanvasTitle] = useState('HRA');

  // Function to handle card click and update title
  const handleCardClick = (title) => {
    setOffcanvasTitle(title);
    const offcanvas = new window.bootstrap.Offcanvas(document.getElementById('healtRecordsTab'));
    offcanvas.show();
  };

  return (
    <>
      <section className='HealthLockerCard mt-3 mb-[60px]'>

        {/* Cards */}
        {['HRA', 'Lab Reports', 'Prescriptions', 'ECG Reports', 'Sonography Reports', 'Vaccination Records', 'Medical History', 'Allergies', 'Medications', 'Assessment'].map((title, index) => (
          <motion.div
            key={title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 * index, duration: 0.5 }}
          >
            <div className='card bg-main-LC-card mb-2' onClick={() => handleCardClick(title)}>
              <div className='card-body p-0'>
                <div className='row align-items-center'>
                    {/* <div className="col-auto text-center">
                        <img src={require("../../../images/icons-hl-LabReports.png")} className="h-10" alt=""/>
                    </div> */}
                  <div className='col ps-4'>
                    <h1 className='LC-main-title mb-0'>{title}</h1>
                  </div>
                  <div className='col-auto'>
                    <i className='bx bx-chevron-right arrow-icon'></i>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        ))}

        {/* Offcanvas */}
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="healtRecordsTab" aria-labelledby="healtRecordsTabLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="healtRecordsTabLabel">{offcanvasTitle}</h5>
            <button type="button" className="btn-close close-btn" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body small pt-0">
            <div className='row align-items-center'>
              <div className='col-12'>
                <div className='bg-health-locker-card'>


                  <Link className='row align-items-center border-bottom-line'>
                    <div className='col-auto ps-3'>
                      <i className="bi bi-file-earmark-pdf pdf-icon"></i>
                    </div>
                    <div className='col px-0'>
                      <h2 className='hl-content-title'>GOQii Health Risk Assessment</h2>
                      <h2 className='hl-sub-title'>15 Aug at 12:32 pm</h2>
                    </div>
                    <div className='col-auto ps-0'>
                      <button className="btn btn-success health-locker-btn mx-2"><i className="bi bi-share"></i></button>
                      <button className="btn btn-success health-locker-btn"><i className="bi bi-trash"></i></button>
                    </div>
                  </Link>

                  <Link className='row align-items-center border-bottom-line'>
                    <div className='col-auto ps-3'>
                      <i className="bi bi-file-earmark-pdf pdf-icon"></i>
                    </div>
                    <div className='col px-0'>
                      <h2 className='hl-content-title'>GOQii Health Risk Assessment</h2>
                      <h2 className='hl-sub-title'>15 Aug at 12:32 pm</h2>
                    </div>
                    <div className='col-auto ps-0'>
                      <button className="btn btn-success health-locker-btn mx-2"><i className="bi bi-share"></i></button>
                      <button className="btn btn-success health-locker-btn"><i className="bi bi-trash"></i></button>
                    </div>
                  </Link>

                  <Link className='row align-items-center border-bottom-line'>
                    <div className='col-auto ps-3'>
                      <i className="bi bi-file-earmark-pdf pdf-icon"></i>
                    </div>
                    <div className='col px-0'>
                      <h2 className='hl-content-title'>GOQii Health Risk Assessment</h2>
                      <h2 className='hl-sub-title'>15 Aug at 12:32 pm</h2>
                    </div>
                    <div className='col-auto ps-0'>
                      <button className="btn btn-success health-locker-btn mx-2"><i className="bi bi-share"></i></button>
                      <button className="btn btn-success health-locker-btn"><i className="bi bi-trash"></i></button>
                    </div>
                  </Link>

                  <Link className='row align-items-center border-bottom-line'>
                    <div className='col-auto ps-3'>
                      <i className="bi bi-file-earmark-pdf pdf-icon"></i>
                    </div>
                    <div className='col px-0'>
                      <h2 className='hl-content-title'>GOQii Health Risk Assessment</h2>
                      <h2 className='hl-sub-title'>15 Aug at 12:32 pm</h2>
                    </div>
                    <div className='col-auto ps-0'>
                      <button className="btn btn-success health-locker-btn mx-2"><i className="bi bi-share"></i></button>
                      <button className="btn btn-success health-locker-btn"><i className="bi bi-trash"></i></button>
                    </div>
                  </Link>

                  <Link className='row align-items-center border-bottom-line'>
                    <div className='col-auto ps-3'>
                      <i className="bi bi-file-earmark-pdf pdf-icon"></i>
                    </div>
                    <div className='col px-0'>
                      <h2 className='hl-content-title'>GOQii Health Risk Assessment</h2>
                      <h2 className='hl-sub-title'>15 Aug at 12:32 pm</h2>
                    </div>
                    <div className='col-auto ps-0'>
                      <button className="btn btn-success health-locker-btn mx-2"><i className="bi bi-share"></i></button>
                      <button className="btn btn-success health-locker-btn"><i className="bi bi-trash"></i></button>
                    </div>
                  </Link>

                  <Link className='row align-items-center border-bottom-line'>
                    <div className='col-auto ps-3'>
                      <i className="bi bi-file-earmark-pdf pdf-icon"></i>
                    </div>
                    <div className='col px-0'>
                      <h2 className='hl-content-title'>GOQii Health Risk Assessment</h2>
                      <h2 className='hl-sub-title'>15 Aug at 12:32 pm</h2>
                    </div>
                    <div className='col-auto ps-0'>
                      <button className="btn btn-success health-locker-btn mx-2"><i className="bi bi-share"></i></button>
                      <button className="btn btn-success health-locker-btn"><i className="bi bi-trash"></i></button>
                    </div>
                  </Link>

                  <Link className='row align-items-center border-bottom-line'>
                    <div className='col-auto ps-3'>
                      <i className="bi bi-file-earmark-pdf pdf-icon"></i>
                    </div>
                    <div className='col px-0'>
                      <h2 className='hl-content-title'>GOQii Health Risk Assessment</h2>
                      <h2 className='hl-sub-title'>15 Aug at 12:32 pm</h2>
                    </div>
                    <div className='col-auto ps-0'>
                      <button className="btn btn-success health-locker-btn mx-2"><i className="bi bi-share"></i></button>
                      <button className="btn btn-success health-locker-btn"><i className="bi bi-trash"></i></button>
                    </div>
                  </Link>



                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default HealthLockerCard;
