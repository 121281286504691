import React, { useState, useEffect } from 'react';
import './WaterLogEntry.css';
import NotificationToast from '../component/NotificationToast';
import { water_log_post_data, water_list_get_user_data,user_delete_log_data } from "../services/Services";

function WaterLogEntry(props) {
  const [activeCategory, setActiveCategory] = useState('200ml');
  const [errors, setErrors] = useState('');
  const [listWater, setListWater] = useState([]);
  const [listWaterCount, setListWaterCount] = useState('0');
  const [success, setSuccess] = useState('');
  const [toastKey, setToastKey] = useState(0); // State variable for the key
  const [isLoading, setIsLoading] = useState(false); // State variable for loader
  const [disable, setDisable] = useState(true);
  const [totalWaterConsumed, setTotalWaterConsumed] = useState(0);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);   
  };

  const submitWaterDataLog=async()=>{
    setDisable(false);
    console.log("final Water submit",activeCategory,props.calendarData);
    setIsLoading(true); // Show loader
    setErrors("");
    if(!activeCategory){
      setIsLoading(false); // Hide loader
      setErrors("Please select a valid water log");
      setDisable(true);
    }else{
      let logData = {
        'logDate':props.calendarData,
        'logName':activeCategory
      }
      try {
        const response = await water_log_post_data(logData);
        if(response.data.code === 200){
          fetchData();
          setSuccess(response.data.data.message);
          //sendDataToAndroid();
          setDisable(true);
        }else{
          setErrors(response.data.data.message);
          setDisable(true);
        }
      } catch (error) {
        console.error(error);
        setErrors("An error occurred while submitting water log data.");
      }
      setIsLoading(false); // Hide loader
    }
   
    setToastKey(prevKey => prevKey + 1);
    //console.log("response food data post", response.data);
    //setFoodPostData(response.data.data);
  }

 /*const sendDataToAndroid = () => {
    var dataToSend = {
      type: "close_screen",
      message: "Log Successfully"
    };
    window.Android.sendDataToAndroid(JSON.stringify(dataToSend));
  }*/
  const fetchData = async () => {
    try {
        console.log("fetch data");
        console.log("props.calendarData",props.calendarData);
        const response = await water_list_get_user_data(props.calendarData);
        console.log("response water_list_get_user_data", response.data.data);
        setListWater(response.data.data);
        console.log("response.data.data.length",response.data.data.length);
        setListWaterCount(response.data.data.length);
        setTotalWaterConsumed(response.data.waterConsumed)
    } catch (error) {
        console.error(error);
        setErrors("An error occurred while fetching water log data.");
    }
    
};
 
const userDeleteLog = async(deleteLogId,type) =>{
  let response;
try {
    var data = {
      type: type,
      deleteLogId: deleteLogId
    };
    console.log("user delete Log",deleteLogId);
    response = await user_delete_log_data(JSON.stringify(data));
    if(response.data.code === 200){
      fetchData();
      setSuccess(response.data.data.message)
    }else{
      setErrors("An error occurred while deleting water log data.");
    }
  } catch (error) {
      console.error(error);
      setErrors("An error occurred while deleting water log data.");
  }
  return response;
}

// useEffect(() => {

//     fetchData();
//   },[success,props.calendarData,errors]);

  return (
    <>
      <section className="WaterLogEntry">
        <div className="floating-loader" style={{ display: isLoading ? 'block' : 'none' }}>
          <div className="loader"></div>
          loading...
        </div>
        <div className="container">
        <div className='row align-items-center'>
                <div className='col'>
                    <h1 className='water-main-title'>Total Water consumed</h1>
                </div>
                <div className='col-auto text-end'>
                <h1 className='water-main-title'><span>{totalWaterConsumed}</span></h1>
                </div>
            </div>
          <div className='row'>
          <div className='col-12 mt-3'>
                <label className="form-label">Quick Log</label>
            </div>
            <div className={`col text-center ${activeCategory === '200ml' ? 'active' : ''}`} onClick={() => handleCategoryClick('200ml')}>
                <img src={require(`../images/${activeCategory === '200ml' ? 'g1-a.png' : 'g1.png'}`)} className="img-fluid waterlog-icon" alt="" />
                <h4 className={`waterlog-title ${activeCategory === '200ml' ? 'active' : ''}`}>200 ml</h4>
            </div>

            <div className={`col text-center ${activeCategory === '500ml' ? 'active' : ''}`} onClick={() => handleCategoryClick('500ml')}>
                <img src={require(`../images/${activeCategory === '500ml' ? 'g2-a.png' : 'g2.png'}`)} className="img-fluid waterlog-icon" alt="" />
                <h4 className={`waterlog-title ${activeCategory === '500ml' ? 'active' : ''}`}>500 ml</h4>
            </div>

            <div className={`col text-center ${activeCategory === '750ml' ? 'active' : ''}`} onClick={() => handleCategoryClick('750ml')}>
                <img src={require(`../images/${activeCategory === '750ml' ? 'g3-a.png' : 'g3.png'}`)} className="img-fluid waterlog-icon" alt="" />
                <h4 className={`waterlog-title ${activeCategory === '750ml' ? 'active' : ''}`}>750 ml</h4>
            </div>

            <div className={`col text-center ${activeCategory === '1Litre' ? 'active' : ''}`} onClick={() => handleCategoryClick('1Litre')}>
                <img src={require(`../images/${activeCategory === '1Litre' ? 'g4-a.png' : 'g4.png'}`)} className="img-fluid waterlog-icon" alt="" />
                <h4 className={`waterlog-title ${activeCategory === '1Litre' ? 'active' : ''}`}>1 Litre</h4>
            </div>
              
          </div>

          {/* {errors && <NotificationToast message={errors} key={toastKey} type="error"/>} */}
          {success && <NotificationToast message={success} key={toastKey} type="success" />}
          
          <div className='row mt-5'>
            <div className='col-6'>
              <button type="button" className="btn btn-success back-btn">Cancel</button>
            </div>
            <div className='col-6'>
              <button type="button" className="btn btn-success next-btn" disabled={!disable} onClick={submitWaterDataLog}>Log</button>
            </div>
            <div className='col-12 mt-3'>
            <hr/>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
                <h2 className='water-detail-title'>
                Logged Water
                </h2>
            </div>
            <div className='col-12 px-4'>
            {listWaterCount === 0 ? (
                <div className="text-center">No water log found.</div>
              ) : (
                listWater.map(water => (
            <div className='row align-items-center py-3 border-bottom' key={water.waterLogId}>
                 <div className='col-auto'>
                    {/* <img src={require(`../images/g1.png`)} className="water-icon1" alt="" /> */}

                    <img src="https://appcdn.goqii.com/storeimg/49268_1715749852.png" className="water-icon1" alt="" />

                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label1'>Water</h3>
                    <h3 className='form-label1'>{water.amountML} L</h3>
                 </div>
                 <div className='col-auto text-end'>
                    <h3 className='form-label1'>Logged at</h3>
                    <h3 className='form-label1'>{water.logTime}</h3>
                    <h3 className='form-label1' onClick={(e) => userDeleteLog(water.waterLogId,'water')}><i class='bx bx-trash'></i></h3>
                 </div>
            </div>
             ))
              )}
             {/* <div className='row align-items-center py-3 border-bottom'>
                 <div className='col-auto'>
                    <img src={require(`../images/g3.png`)} className="water-icon1" alt="" />
                 </div>
                 <div className='col ps-0'>
                    <h3 className='form-label1'>Water</h3>
                    <h3 className='form-label1'>2.9 L</h3>
                 </div>
                 <div className='col-auto text-end'>
                    <h3 className='form-label1'>Logged at</h3>
                    <h3 className='form-label1'>05:04 PM</h3>
                 </div>
            </div> */}
            </div>
        </div>


        </div>
      </section>
    </>
  );
}

export default WaterLogEntry;
