import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './App.css';
import Protected from './Protected';
import  Habits  from './pages/Habits';
import FoodLog from './pages/FoodLog';
import HomePage from './pages/HomePage/HomePage';
import ActivityLog from './pages/ActivityLog';
import WaterLog from './pages/WaterLog';
import SleepLog from './pages/SleepLog';
import WeightLog from './pages/WeightLog';
import ErrorPage from './pages/ErrorPage';
import HomeCard from './pages/HomePage/HomeCard';
import CallWithYourCareNavigator from './pages/CallWithYourCareNavigator/CallWithYourCareNavigator';
import RateCNConsultation from './pages/RateCNConsultation/RateCNConsultation';
import CareNavigatorChat from './pages/CareNavigatorChat/CareNavigatorChat';
import StepsDetail from './pages/StepsDetail/StepsDetail';
import WaterDetail from './pages/WaterDetail/WaterDetail';
import SleepDetail from './pages/SleepDetail/SleepDetail';
import HealthLocker from './pages/HealthLocker/HealthLocker';
import JoinDiabeticWellnessPlan from './pages/JoinDiabeticWellnessPlan/JoinDiabeticWellnessPlan';
import SelectYourCareNavigator from './pages/SelectYourCareNavigator/SelectYourCareNavigator';
import ScheduleCall from './pages/ScheduleCall/ScheduleCall';
import HealthRiskAssessment from './pages/HealthRiskAssessment/HealthRiskAssessment';
import HRAQuestionnaire from './pages/HRAQuestionnaire/HRAQuestionnaire';
import HRAViewReport from './pages/HRAQuestionnaire/component/HRAViewReport';
import Appointments from './pages/Appointments/Appointments';
import MainPage from './pages/MainPage/MainPage';
import Play from './pages/PlayPage/Play';
import Arena from './pages/ArenaPage/Arena';




function App() {
  return (
    <Router>
      <div className="App">
      <Helmet>
        <title>Diabetic Wellness Program</title>
      </Helmet>
        <Routes>
          <Route exact path="/" element={<Protected Component={MainPage } homeTab="Y" coachProfile="Y" bottomMenu="Y" title='Diabetic Wellness Program'/>} />
          <Route path='mainpage' element={<Protected Component={MainPage } homeTab="Y" coachProfile="Y" bottomMenu="Y" title='Diabetic Wellness Program'/>} />
          <Route path="/homecard" element={<HomeCard />} />
          <Route path='/call-with-your-care-navigator' element={<Protected Component={CallWithYourCareNavigator } homeTab="N" coachProfile="N" bottomMenu="N" title='Diabetic Wellness Program'/>} />
          <Route path='/rate-cn-consultation/:uid/:cnId?' element={<Protected Component={RateCNConsultation } homeTab="N" coachProfile="N" bottomMenu="N" title='Rate consultation with Amaira Shaikh' />} />
          <Route path='homepage' element={<Protected Component={HomePage } homeTab="N" coachProfile="Y" bottomMenu="N" title='Diabetic Wellness Program'/>} />
          <Route path='care-navigator-chat' element={<Protected Component={CareNavigatorChat } homeTab="N" coachProfile="N" bottomMenu="N" title='Care Navigator Chat'/>} />
          <Route path='steps-detail' element={<Protected Component={StepsDetail } homeTab="N" coachProfile="N" bottomMenu="N" title='Take More Steps'/>} />
          <Route path='water-detail' element={<Protected Component={WaterDetail } homeTab="N" coachProfile="N" bottomMenu="N" title='Be Hydrated'/>} />
          <Route path='sleep-detail' element={<Protected Component={SleepDetail } homeTab="N" coachProfile="N" bottomMenu="N" title='Sleep Well'/>} />
          <Route path='health-locker' element={<Protected Component={HealthLocker } homeTab="N" coachProfile="N" bottomMenu="Y" title='Health Locker'/>} />
          <Route path='play' element={<Protected Component={Play} homeTab="N" coachProfile="N" bottomMenu="Y" title='Play'/>} />
          <Route path='arena' element={<Protected Component={Arena} homeTab="N" coachProfile="N" bottomMenu="Y" title='Arena'/>} />
          <Route path='join-diabetic-wellness-plan' element={<Protected Component={JoinDiabeticWellnessPlan } homeTab="N" coachProfile="N" bottomMenu="N" title='Diabetic Wellness Plan'/>} />
          <Route path='select-your-care-navigator' element={<Protected Component={SelectYourCareNavigator } homeTab="N" coachProfile="N" bottomMenu="N" title='Select Your Care Navigator'/>} />
          <Route path='schedule-call' element={<Protected Component={ScheduleCall } homeTab="N" coachProfile="N" bottomMenu="N" title='Schedule a Call'/>} />
          <Route path='health-risk-assessment' element={<Protected Component={HealthRiskAssessment } homeTab="N" coachProfile="N" bottomMenu="N" title='Health Risk Assessment'/>} />
          <Route path='hra-questionnaire' element={<Protected Component={HRAQuestionnaire} homeTab="N" coachProfile="N" bottomMenu="N" title='Health Risk Assessment'/>} />
          <Route path='hra-view-report/:uid?' element={<Protected Component={HRAViewReport} homeTab="N" coachProfile="N" bottomMenu="N" title='Health Risk Assessment'/>} />
          <Route path='appointments' element={<Protected Component={Appointments} homeTab="N" coachProfile="N" bottomMenu="N" title='Appointments'/>} />

          <Route path="/step" element={<Habits />} />
          <Route path="/sleep" element={<Habits />} />
          <Route path="/heartrate" element={<Habits />} />
          <Route path="/spo" element={<Habits />} />
          <Route path="/water" element={<Habits />} />
          <Route path="/food-log" element={<FoodLog />} />
          <Route path="/activity-log" element={<ActivityLog />} />
          <Route path="/water-log" element={<WaterLog />} />
          <Route path="/sleep-log" element={<SleepLog />} />
          <Route path="/weight-log" element={<WeightLog  />} />

          

          {/* Route for the ErrorPage component (catch-all route) */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </Router>
  );
}


export default App;
