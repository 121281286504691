import React, { useState } from "react";
import Picker from "react-mobile-picker-scroll";

const CalendarHomePicker = ({ onDone }) => {
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate();

  // Function to get the number of days in a month for a given year and month
  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Function to generate an array of years from current year to past 50 years
  const generateYears = () => {
    const years = [];
    const startYear = currentYear - 50;
    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };

  // Update optionGroups based on the current date
  const optionGroups = {
    title: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    firstName: Array.from({ length: daysInMonth(currentYear, currentMonthIndex) }, (_, i) => i + 1),
    selectedYear: generateYears()
  };

  const [valueGroups, setValueGroups] = useState({
    title: optionGroups.title[currentMonthIndex],
    firstName: currentDay,
    year: currentYear
  });

  // Update the value in response to user picking event
  const handleChange = (name, value) => {
    setValueGroups((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Function to handle "Done" button click
  const handleDone = () => {
    onDone(valueGroups);
  };

  // Disable future months and dates
  const disableFutureMonths = (monthIndex) => {
    return monthIndex > currentMonthIndex;
  };

  const disableFutureDates = (day, monthIndex) => {
    return monthIndex === currentMonthIndex && day > currentDay;
  };

  return (
    <>
      <div className="modal-body">
        <Picker
          optionGroups={optionGroups}
          valueGroups={valueGroups}
          onChange={handleChange}
          optionDisabled={{ title: disableFutureMonths, firstName: disableFutureDates }}
        />
      </div>
      <div className="modal-footer text-center">
        <button type="button" className="btn weight-btn" data-bs-dismiss="modal">
          CANCEL
        </button>
        <button type="button" className="btn weight-btn" onClick={handleDone} data-bs-dismiss="modal">
          DONE
        </button>
      </div>
    </>
  );
};

export default CalendarHomePicker;
