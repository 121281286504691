import React, { useEffect, useState, useRef } from 'react';
import './UpcomingLabTests.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion";
function UpcomingLabTests() {
    return (
        <>
            <section className='UpcomingLabTests mt-4'>
                <div className='container'>
                    <div className='row align-items-center mb-2'>
                    <div className='col'>
                            <h1 className='LabTests-title'>Upcoming Lab Tests</h1>
                        </div>
                        <div className='col-auto'>
                            <button type="button" class="btn view-all">View All</button>
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-12'>
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={2}
                            >
                                <SwiperSlide>
                                    <>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20  }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2, duration: 0.5 }}
                                        className='UpcomingLabTests-box'
                                        >
                                        <div className='LabTests-img-box'>
                                            <h2 className='LabTests-main-title'>HbA1C</h2>
                                        </div>
                                        <div className='LabTests-box-gredient px-3 py-2'>
                                            <h4 className='LabTests-box-date mb-1'>Date: 23 Aug 2024</h4>
                                            <h4 className='LabTests-box-date mb-1'>Time: 09:30 am</h4>
                                            <h4 className='LabTests-box-date'>Fasting before test</h4>
                                        </div>
                                    </motion.div>
                                    </>
                                </SwiperSlide>
                            </Swiper>
                         </div>                 
                    </div>
                           
                </div>
            </section>
        </>
    );
}

export default UpcomingLabTests;
    