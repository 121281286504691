import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './CalendarView.css';

function CalendarView(props) {
  // Get current date
  const currentDate = new Date();

  // State variables
  const [startDate, setStartDate] = useState(getStartOfWeek(currentDate));
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedActiveDate, setSelectedActiveDate] = useState('active');
  const [selectedActiveClassDate, setSelectedActiveClassDate] = useState('active');

  // Function to get the start date of the week
  function getStartOfWeek(date) {
    const dayOfWeek = date.getDay() || 7; // Sunday is 0, so we change it to 7
    const startDate = new Date(date);
    startDate.setDate(startDate.getDate() - dayOfWeek + 1);
    return startDate;
  }

  // Function to handle navigation to the next week
  function handleNextWeek() {
    const nextWeekStartDate = new Date(startDate);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
    setStartDate(nextWeekStartDate);
  }

  // Function to handle navigation to the previous week
  function handlePreviousWeek() {
    const previousWeekStartDate = new Date(startDate);
    previousWeekStartDate.setDate(previousWeekStartDate.getDate() - 7);
    setStartDate(previousWeekStartDate);
  }

  function handleDateSelect(date) {
    // Check if the selected date is in the future
    const isFutureDate = date >= currentDate;
    
    // If it's not a future date, update the selected date
    if (!isFutureDate) {
      const localDate = new Date(date);
      const gmtDateString = localDate.toUTCString();
      setSelectedDate(localDate);
      setSelectedActiveDate(date.toDateString());
      setSelectedActiveClassDate('')
      props.setCalendarData(gmtDateString);
    }
  }

  // Generate an array of dates for the current week
  const weekDates = [...Array(7)].map((_, index) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + index);
    return date;
  });

  // Determine if the next week is in the future
  const nextWeekStartDate = new Date(startDate);
  nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
  const isFutureWeek = nextWeekStartDate >= currentDate;

  return (
    <section className="FoodLog-calendar">
       <span className="mx-3 month-year">
        {weekDates[0].toLocaleDateString('en-US', { month: 'long' })} {weekDates[0].getFullYear()}
      </span>
      <div className="container">
        <div className='row'>
          <div className='col-2 px-0 text-center'>
            <button className='btn arrow-btn' onClick={handlePreviousWeek}><i class="bi bi-chevron-left"></i></button>
          </div>
          <div className='col-8 px-0 mb-2'>
          <Swiper
              slidesPerView={7}
              spaceBetween={7}
              className="myCalendar"
            >
              {weekDates.map((date, index) => (
                <SwiperSlide key={index}>
                  <div className={`text-center ${selectedDate && selectedDate.toDateString() === date.toDateString() ? 'selected' : ''}`} onClick={() => handleDateSelect(date)}>
                    <h2 className='days-title'>
                      {['M', 'T', 'W', 'T', 'F', 'S', 'S'][index]}
                    </h2>
                    {selectedDate.toDateString() === date.toDateString() ? (
                    <h3 className={`days-subtitle ${date.getDate() !== currentDate.getDate() ? 'active' : 'active'}`}>
                      {date.getDate()}
                    </h3>
                    ):(<h3 className={`days-subtitle ${date.getDate() === currentDate.getDate() ? selectedActiveClassDate : ''}`}>
                    {date.getDate()}
                  </h3>)
                    }   
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className='col-2 px-0 text-center'>
          <button className='btn arrow-btn' onClick={handleNextWeek} disabled={isFutureWeek}><i class="bi bi-chevron-right"></i></button>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default CalendarView;
