import React, { useState } from 'react';
import './CareNavigatorSlotBook.css';
import { Link } from 'react-router-dom';

const CareNavigatorSlotBook = () => {
  const [selectedSlot, setSelectedSlot] = useState('10:30 am');

  const slots = [
    "10:30 am",
    "02:00 pm",
    "04:15 pm",
    "06:00 pm",
    "08:00 pm"
  ];

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  return (
    <>
      <section className='CareNavigatorSlotBook mt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <div className='calender-box-slot'>
                <button className='btn icon-slot-arrow'><i className="bi bi-chevron-left"></i></button>
                <h2 className='slot-book-title'>Today</h2>
                <button className='btn icon-slot-arrow'><i className="bi bi-chevron-right"></i></button>
              </div>
            </div>
            <div className='col-12 mt-3'>
              <div className='book-box-slot'>
                {slots.map((slot, index) => (
                  <button 
                    key={index}
                    type="button" 
                    className={`btn btn-box-slot ${selectedSlot === slot ? 'btn-box-slot-active' : ''}`}
                    onClick={() => handleSlotClick(slot)}
                  >
                    {slot}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='bottom-fixed-button px-3'>
        <button className="btn action-button btn-enrollNow" data-bs-toggle="modal" data-bs-target="#enrollNowModal" disabled={!selectedSlot}>
          Schedule a Call
        </button>
      </div> 

      <div className="modal fade" id="enrollNowModal" tabIndex="-1" aria-labelledby="enrollNowModalLabel" data-bs-backdrop="static" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <button type="button" className="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body pt-0">
              <div className="row">
                <div className="col-12 my-3 text-center">
                  <img src={require("../../../images/icon-thumbs-up.png")} className='h-20 d-inline' alt="" />
                  <h2 className='maintitle my-3'>Great, all set!</h2>
                  <h3 className='JDW-content-text my-3'>
                    Your appointment is scheduled today at {selectedSlot} with Care Navigator Amaira Shaikh
                  </h3>
                </div>
                <Link to="/health-risk-assessment" className="btn action-button btn-join">Procced</Link>
              </div>
            </div>
          </div>
        </div>
      </div>        
    </>
  );
};

export default CareNavigatorSlotBook;
