import React, { useEffect, useState, useRef } from 'react';
import './CalendarHome.css';
import CalendarHomePicker from './CalendarHomePicker';
import { Swiper, SwiperSlide } from 'swiper/react';

function CalendarHome() {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const [currentDayDate, setCurrentDayDate] = useState(currentDay);
  const [activeMode, setActiveMode] = useState('active');
  const [inactiveMode, setInActiveMode] = useState('inactive');
  const currentDayIndex = currentDate.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const [selectedValue, setSelectedValue] = useState({
    title: currentDate.toLocaleString('default', { month: 'short' }),
    firstName: currentDay.toString(),
  });
  const [modalOpen, setModalOpen] = useState(false);

  const swiperRef = useRef(null); // Reference to Swiper instance

  // Function to handle picker done
  const handlePickerDone = (value) => {
    const selectedDateIndex = weekDates.findIndex(day => day.date === parseInt(value.firstName, 10));
    setSelectedValue(value);
    setCurrentDayDate(parseInt(value.firstName, 10)); // Update the selected date
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(selectedDateIndex); // Scroll to the selected date
    }
    setModalOpen(false); // Close the modal after selecting value
    console.log("calendar", value);
    console.log(value.title);
  };

  const currentYear = currentDate.getFullYear();
  const totalDaysInMonth = new Date(currentYear, currentDate.getMonth() + 1, 0).getDate(); // Get total days in the current month
  console.log('totalDaysInMonth', totalDaysInMonth);

  const startDate = new Date(currentDate);
  startDate.setDate(currentDay - currentDayIndex + (currentDayIndex === 0 ? -6 : 1));

  // Generate dates for 2 weeks: previous and current week
  const generateWeekDates = (startDate) => {
    const weekDates = [];
    for (let i = 0; i < 14; i++) {  // 14 days (1 previous week + current week)
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i - 7);  // -7 to start from the previous week
      weekDates.push({
        date: date.getDate(),
        month: date.toLocaleString('default', { month: 'short' }),
        year: date.getFullYear(),
      });
    }
    return weekDates;
  };

  const weekDates = generateWeekDates(startDate);

  // Find the index of the current day in the combined array
  const getCurrentDaySlideIndex = () => {
    return weekDates.findIndex(day => day.date === currentDayDate);
  };

  const [currentSlideIndex, setCurrentSlideIndex] = useState(getCurrentDaySlideIndex());

  useEffect(() => {
    setCurrentSlideIndex(getCurrentDaySlideIndex());
  }, [currentDayDate, weekDates]);

  const selectedDate = (day) => {
    console.log('Date selected:', day.date);
    setCurrentDayDate(day.date);
    setSelectedValue({
      title: day.month,
      firstName: day.date.toString(),
    });
    setModalOpen(true);  // Open the CalendarHomePicker modal
  };

  return (
    <>
      <section className="CalendarHome mt-3">
        <div className="container">
          <div className='row'>
            <div className="col-4">
            <label className='h-calendar-title' data-bs-toggle="modal" data-bs-target="#calendarHomeModal">
  {selectedValue.title} '{selectedValue.firstName} <i className='bx bxs-down-arrow'></i>
</label>
            </div>
            <div className="col-8 ps-1">
              <Swiper
                ref={swiperRef} // Attach the ref to Swiper
                spaceBetween={10}
                slidesPerView={7}
                initialSlide={currentSlideIndex} // Focus on current day by default
                onSlideChange={() => setModalOpen(false)} // Close modal if slide changes
              >
                {weekDates.map((day, index) => (
                  <SwiperSlide key={index}>
                    <div className={`text-center ${index % 7 === 0 || index % 7 === 6 ? 'weekend' : ''}`}>
                      <h2 className="h-days-title">{daysOfWeek[index % 7]}</h2>
                      <h3 className={`h-days-subtitle ${currentDayDate === day.date ? activeMode : inactiveMode}`}>
                        <font onClick={() => selectedDate(day)}>{day.date}</font>
                      </h3>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for CalendarHomePicker */}
     <div className={`modal fade ${modalOpen ? 'show' : ''}`} id="calendarHomeModal" tabIndex="-1" aria-labelledby="calendarHomeLabel" aria-hidden={!modalOpen}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content CalendarHomeModule">
          <CalendarHomePicker onDone={handlePickerDone} />
        </div>
      </div>
    </div>
    </>
  );
}

export default CalendarHome;
