import React,{useState,useEffect} from 'react';
import './Play.css';
import { homepage_recommended_video } from '../../services/Services';
import RecommendedVideos from '../HomePage/component/RecommendedVideos';

function Play() {

  return (
    <>
   <section className='Play'>
                <div className='container'>
                    <div className='row align-items-center'>
                    {/* <div className='col'>
                            <h1 className='Play-title'>Play</h1>
                    </div> */}
                    {/* <div className='col-12 mb-3 mt-[150px] text-center d-flex justify-content-center'>
                      <img src="https://appcdn.goqii.com/storeimg/98520_1719229340.png" alt="" className='h-[220px]'/>
                    </div>
                    <div className='col-12 mb-3 text-center'>
                    <h1 class="modal-title">Upcoming Feature:</h1>
                    <h4 class="modal-subtitle">Available in Phase 2!</h4>
                    </div> */}
                    <RecommendedVideos/>
            
                    {/* <iframe
        src="https://goqiipro.goqii.com/landing/NEdRWXd0aElkd1U3dUl3VHVnQU1TZz09"
        title="Landing Page"
        style={{ width: '100%', height: '85vh', border: 'none',borderRadius:'20px' }}
      /> */}
                    </div>
    </div>
    </section>
    </>
  );
}
export default Play;