import React,{useState,useEffect} from 'react';
import { Helmet } from 'react-helmet';
import CalendarView from "../component/CalendarView";
import WaterLogEntry from "../component/WaterLogEntry";
import {verify_users_access } from "../services/Services";

import './WaterLog.css';


function WaterLog() {
  const [calendarData, setCalendarData] = useState([]);
  const [previousDateLimit, setPreviousDateLimit] = useState('');

  //select calendar 
  const waterCalendarLog= (data) => {
    setCalendarData(data);
    console.log('parent Calendar Log',data)
  };

  useEffect(() => {

    const fetchData = async () => {
        try {
            console.log("verify data");
            const response = await verify_users_access();
            console.log("response verify data", response);
            if(response.data.data.code !== 200){
              setPreviousDateLimit(response.data.data.registrationDate);
            }
        } catch (error) {
            console.error(error);
        }
    };
    fetchData();
   
  }, []);
  return (
    <>
     <Helmet>
        <title>Water Log</title>
      </Helmet>
      <CalendarView setCalendarData = {waterCalendarLog} previousDateLimit={previousDateLimit}/>
      <WaterLogEntry calendarData = {calendarData}/>

    </>
  );
}

export default WaterLog;
