import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d';

const SleepGraph = ({sleepData,type}) => {
    console.log("sleep data", sleepData)
    console.log("sleep type", type)
    useEffect(() => {
        var options = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: '',
                },
                credits: {
                    enabled: false,
                  },
                xAxis: {
                    categories: sleepData.map(entry => entry.bedTime),
                    lineColor: 'transparent', // Set the line color to transparent
                    lineWidth: 0,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'No. of hours'
                    },
                    gridLineWidth: 1, // Set the width of the grid lines
                    gridLineDashStyle: 'dot',
                    gridLineColor: '#646464',
                    stackLabels: {
                        enabled: true
                    }
                },
                // tooltip: {
                //     headerFormat: '<b>{point.x}</b><br/>',
                //     pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                // },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: 0.1, // Adjust the padding between columns
                        borderWidth: 0, // Remove column border
                        pointWidth: 30, // Set the width of the columns
                        borderRadius: 2,
                        // dataLabels: {
                        //     enabled: true
                        // }
                    }
                },
                series: [
                    // {
                    //     name: 'Almost Awake',
                    //     data: sleepData.map(entry => parseInt(entry.awakeSleep)),
                    //     stack: 'sleep',
                    // },
                    {
                        name: 'sleep',
                        data: sleepData.map(entry => parseInt(entry.deepSleep)),
                        stack: 'sleep',
                    },
                    // {
                    //     name: 'Light Sleep',
                    //     data: sleepData.map(entry => parseInt(entry.lightSleep)),
                    //     stack: 'sleep',
                    // },
                    // {
                    //     name: 'REM Sleep',
                    //     data: sleepData.map(entry => parseInt(entry.remSleep)),
                    //     stack: 'sleep',
                    // },
                    // {
                    //     type: 'line',
                    //     name: 'Target Line',
                    //     data: Array(sleepData.length).fill(3),
                    //     color: 'red',
                    //     marker: {
                    //         enabled: false
                    //     },
                    //     states: {
                    //         hover: {
                    //             lineWidth: 0
                    //         }
                    //     }
                    // }],
                ],
            };
        Highcharts.chart('chart-container', options);
    }, [sleepData]); 
    return (
    <div>
        <div id="chart-container">
            <HighchartsReact highcharts={Highcharts} options={{}} />
        </div>
    </div>
    )
}

export default SleepGraph