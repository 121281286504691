import React, { useState } from "react";
import './StarRatings.css';

function Star({ selected, onClick }) {
  return (
    <i
      className={`bx bxs-star StarRatingsTitle`}
      style={{ color: selected ? "#EAC448" : "#d7d7d7", cursor: "pointer", margin:"0 5px" }}
      onClick={onClick}
    ></i>
  );
}

function StarRatings({parentCallback}) {
  const [rating, setRating] = useState(0);

  function clickHandler(val) {
    setRating(val);
    parentCallback(val);
    console.log("stars",val);
  }

  function renderStars() {
    return [1, 2, 3, 4, 5].map((val, idx) => (
      <Star
        key={idx}
        selected={val <= rating}
        onClick={() => clickHandler(val)}
      />
    ));
  }

  return (
    <div>
      {renderStars()}
      {/* <h4>{`Results: ${rating}/5`}</h4> */}
    </div>
  );
}

export default StarRatings;
