import React,{ useEffect }  from 'react';
import { motion } from 'framer-motion';
import CareNavigatorProfile from './component/CareNavigatorProfile';

import './SelectYourCareNavigator.css';


function SelectYourCareNavigator() {
  useEffect(() => {
    // Check if the URL already contains a refresh flag (for one-time refresh)
    const urlParams = new URLSearchParams(window.location.search);
    const hasRefreshed = urlParams.get('refreshed');

    if (!hasRefreshed) {
      // Append a query param to indicate that the page has refreshed
      urlParams.set('refreshed', 'true');
      const newUrl = window.location.pathname + '?' + urlParams.toString();
      window.location.replace(newUrl); // Refresh the page by updating the URL
    }
  }, []);
  return (
    <>
    <motion.section 
        className="SelectYourCareNavigator mt-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >

        <div className='container'>
          <div className='row'>
              <div className='col-12 mb-3 px-0'>
                <CareNavigatorProfile/>
              </div>
          </div>
        </div>
        
    </motion.section>

    </>
  );
}

export default SelectYourCareNavigator;
