import React, { useState } from 'react';
import { motion } from 'framer-motion';

function HRAQ4({ updateHraData, hraData }) {

    const handleRadioChange = (e) => {
        const { value, name } = e.target;
        updateHraData(name, value);  // Update the parent component's state
      };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      className=''
    >
      <div className='row'>
        <div className="col-12 mb-3">
          <h1 className='hra-title'>How many glasses of water do you drink per day?</h1>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="radio"
              className="form-check-input form-radio-input-ui"
              name="waterIntake"
              id="hraRadios7"
              value="2-4 Glasses"
            //   checked={hraData.waterIntake === '2-4 Glasses'}
              onChange={handleRadioChange}
            />
            <label htmlFor='hraRadios7' className="form-check-label form-label-input mt-[6px]">2-4 Glasses</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="radio"
              className="form-check-input form-radio-input-ui"
              name="waterIntake"
              id="hraRadios8"
              value="4-8 Glasses"
            //   checked={hraData.waterIntake === '4-8 Glasses'}
              onChange={handleRadioChange}
            />
            <label htmlFor='hraRadios8' className="form-check-label form-label-input mt-[6px]">4-8 Glasses</label>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check d-flex">
            <input
              type="radio"
              className="form-check-input form-radio-input-ui"
              name="waterIntake"
              id="hraRadios9"
              value="8-12 Glasses or More"
            //   checked={hraData.waterIntake === '8-12 Glasses or More'}
              onChange={handleRadioChange}
            />
            <label htmlFor='hraRadios9' className="form-check-label form-label-input mt-[6px]">8-12 Glasses or More</label>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default HRAQ4;
