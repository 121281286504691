import React,{useState,useEffect} from 'react';
import { motion } from 'framer-motion';
import { useParams } from "react-router-dom";
import './HRAViewReport.css';
import { homepage_hra } from '../../../services/Services';


function HRAViewReport() {
    const { uid } = useParams();
    console.log("memberShip No",uid);
    const [muid,setMuid] = useState(uid)
    if (!muid) {
       setMuid(localStorage.getItem('uid'));
    }
    const [hraData, setHraData] = useState([]);
    
    const [hraReportData, setHraReportData] = useState([]);


    const fetchData = async () => {
        try {
            const response = await homepage_hra(muid);
            console.log("API Response:", response.data.data);
            setHraData(response.data.data);
            setHraReportData(response.data.data.hraJson);
            console.log("count",response.data.data.hraCount)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
  
    useEffect(() => {
        fetchData();
    }, [uid]);


    const numberOfKeys = Object.keys(hraReportData).length;
  return (
    <>
    <motion.section 
        className="HRAViewReport"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >
        <div className='container'>
          <div className='row'>
              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        className='text-center'
                        >
                        <h1 className='submaintitle mb-2'>HRA Score</h1>
                        <h1 className={`report-maintitle mb-4 ${hraData.hraHeaderLabelCss}`}><span>{hraData.hraHeaderText}</span></h1>
                </motion.div>
              </div>

              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                        className=''
                        >
                        <div className="strip" style={{left: '50%'}}>
                        {hraData.hraScore > 0 ? (
                            <span className='hraPointer'>{hraData.hraScore}</span>
                        ) : (<span className='hraPointer'>5</span>)}
                            <p className="strip-gap"><i className="fa fa-caret-down" aria-hidden="true"></i></p>
                        </div>

                        <div className="progress">
                            <div className="progress-bar bg-High" style={{"width": "35%"}}>High</div>
                            <div className="progress-bar bg-Moderate" style={{"width": "30%"}}>Moderate</div>
                            <div className="progress-bar bg-Healthy" style={{"width": "20%"}}>Healthy</div>
                            <div className="progress-bar bg-Optimum " style={{"width": "15%"}}>Optimum</div>
                        </div>
                </motion.div>
              </div>

              <div className='col-12 mt-5 px-5'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                        className='text-center d-flex justify-content-center'
                        >
                             {numberOfKeys > 0 ? (
                       <button className="btn action-button btn-enrollNow" data-bs-toggle="modal" data-bs-target="#hraViewReportModal" >View Report</button>
                             ):('')}
                </motion.div>
              </div>
              
             
          </div>
        </div>
        
        <div className="modal fade" id="hraViewReportModal" tabindex="-1" aria-labelledby="hraViewReportModalLabel" data-bs-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">HRA</h5>
                    <button type="button" className="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col-12">
                        
                    
                        {numberOfKeys > 0 ? (
                            <ul className="list-group list-group-flush">
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>Input weight in kgs</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_1.value > 0 ? hraReportData.answer_1.value : 0}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>Input height in cms</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_2.value > 0 ? hraReportData.answer_2.value : 0}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            question
                            <h3 className='view-report-content mb-2'>What is your goal within the next year?</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_3.value != 0 ? hraReportData.answer_3.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>Have you used any weight loss product (pills or injection) or have done any weight loss surgery before?</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_3_1.value != 0 ? hraReportData.answer_3_1.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>Do you have any health conditions?</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_4.value != 0 ? hraReportData.answer_4.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>How many glasses of water you drink per day?</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_5.value != 0 ? hraReportData.answer_5.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>How often you do exercise?</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_6.value != 0 ? hraReportData.answer_6.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>How many meals you eat per day including snacks?</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_7.value != 0 ? hraReportData.answer_7.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>Which of the following food groups are included in your daily routine (more than 4 times a week)? You can select more than one</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_8.value != 0 ? hraReportData.answer_8.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>Specified:</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.answer_4.value != 0 ? hraReportData.answer_4.value : ''}</span></h3>
                        </li>
                        <li className="list-group-item px-0">
                            <h3 className='view-report-content mb-2'>BMI:</h3>
                            <h3 className='view-report-content mb-0'><span>{hraReportData.bmi.value}</span></h3>
                        </li>
                        </ul>
                        ) : ('No Data')}
                    
                    </div>
                </div>

                </div>
                </div>
            </div>
            </div>

    </motion.section>

    </>
  );
}

export default HRAViewReport;
