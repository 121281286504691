import React,{useState,useEffect} from 'react';
import { motion } from "framer-motion";
import './RateCNConsultation.css';
import { Navigate, useParams } from "react-router-dom";

import CoachDetailsCard from './component/CoachDetailsCard';
import CoachRatingCard from './component/CoachRatingCard';
import { care_navigator_list } from '../../services/Services';


const RateCNConsultation = () => {
  const { uid,cnId } = useParams();
  console.log("membership No",uid);
  console.log("carenavigator name",cnId);
  const [readData, setReadData] = useState([]);

  useEffect(() => {
      fetchData();
      
  },[cnId,uid]);

const fetchData =async()=>{
    try {
        const response = await care_navigator_list(uid,cnId);
        console.log("care_navigator_list", response.data);
        setReadData(response.data.data);
    } catch (error) {
        console.error(error);
    }
}
  return (
    <>
    <motion.section 
        className="RateCNConsultation mt-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >

        <div className='container'>
          <div class="row">
              <div className='col-12 mb-3 px-1'>
                <motion.div
                    initial={{ opacity: 0, y: 20  }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    >
                     <CoachDetailsCard readData={readData} />
                </motion.div>
              </div>
              <div className='col-12 mb-3 px-1'>
                <motion.div
                    initial={{ opacity: 0, y: 20  }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    >
                     <CoachRatingCard readData={readData} />
                </motion.div>
              </div>
          </div>
        </div>
        
    </motion.section>
    </>
  );
};

export default RateCNConsultation;