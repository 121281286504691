import React,{useState,useEffect} from 'react';
import './HomeHRA.css';
import HRACard from '../../CallWithYourCareNavigator/component/HRACard';
import { homepage_hra } from '../../../services/Services';

function HomeHRA() {
    const uid = localStorage.getItem('uid');
    const [hraData, setHraData] = useState([]);
  
    useEffect(() => {
      fetchData();
  },[uid]);
  
  const fetchData =async()=>{
    try {
  
        const response = await homepage_hra(uid);
        console.log("homepage_hra", response.data.data);
        setHraData(response.data.data);
    } catch (error) {
        console.error(error);
    }
  }
  return (
    <>
        {hraData.hraCount > 0 ? (

<section className='HomeHRA mt-4'>
<div className='container'>
    <div className='row'>
        <div className='col-12'>
            <h1 className='HomeHRA-title'>Your HRA report is ready !</h1>
        </div>

        <div className='col-12 mt-3'>
            <HRACard hraData={hraData}/>
        </div>
    </div>
</div>
</section>

        ) : ('')}
        

    </>
  );
}

export default HomeHRA;
