import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Appointments.css';

import UpcomingCard from './component/UpcomingCard';
import PastCard from './component/PastCard';


function Appointments() {
  const [activeTab, setActiveTab] = useState('upcoming');

  const handleTabClick = (tab) => {
      setActiveTab(tab);
  };

  return (
    <>
        <motion.section 
          className="Appointments"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.5 }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-12 mb-3 px-0'>
              <div className="tabs-container">
                <div className="tabs-header">
                    <div 
                        className={`tab-item ${activeTab === 'upcoming' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('upcoming')}
                    >
                        Upcoming
                    </div>
                    <div 
                        className={`tab-item ${activeTab === 'past' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('past')}
                    >
                        Past
                    </div>
                </div>
                <div className="tabs-content">
                      {activeTab === 'upcoming' && (
                          <div className="tab-content">
                              <UpcomingCard />
                            </div>
                        )}
                        {activeTab === 'past' && (
                            <div className="tab-content">
                                <PastCard />
                            </div>
                        )}
                    </div>
                </div>
              
              </div>
            </div>
          </div>
        </motion.section>
    </>
  );
}

export default Appointments;
