import React from 'react';
import { motion } from 'framer-motion';

function HRAQ1({ updateHraData, hraData }) {

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        updateHraData(id, value);  // Update the parent component's state
      };
  return (
    <>
    <motion.div
        initial={{ opacity: 0, y: 20  }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className=''
    >
    <div className='row'>
        <div class="col-12 mb-3">
            <h1 className='hra-title'>Please tell us about your Weight and Height</h1>
        </div>
        <div class="col-12 mb-3">
            <label htmlFor='hra-1' className="form-label form-label-input">Weight</label>
            <input type="text" className="form-control control-input-ui" id="weight" 
            placeholder="Input weight in kilograms"
            value={hraData.weight}  // Controlled component
            onChange={handleInputChange} />
        </div>
        <div class="col-12 mb-3">
            <label htmlFor='hra-2' className="form-label form-label-input">Height</label>
            <input type="text" className="form-control control-input-ui"  id="height" 
            placeholder="Input height in cms"
            value={hraData.height}  // Controlled component
            onChange={handleInputChange} />
        </div>
    </div>
    </motion.div>
    </>
  );
}

export default HRAQ1;
