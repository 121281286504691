import React,{ useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import './HealthRiskAssessment.css';


function HealthRiskAssessment() {

  useEffect(() => {
    // Check if the URL already contains a refresh flag (for one-time refresh)
    const urlParams = new URLSearchParams(window.location.search);
    const hasRefreshed = urlParams.get('refreshed');

    if (!hasRefreshed) {
      // Append a query param to indicate that the page has refreshed
      urlParams.set('refreshed', 'true');
      const newUrl = window.location.pathname + '?' + urlParams.toString();
      window.location.replace(newUrl); // Refresh the page by updating the URL
    }
  }, []);
  return (
    <>
    <motion.section 
        className="HealthRiskAssessment"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        >
        <div className='container'>
          <div className='row'>
              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        className='text-center d-flex justify-content-center'
                        >
                    <img src={require("../../images/icon-HRA-new.png")} class="h-[120px]" alt=""/>
                </motion.div>
              </div>
              <div className='col-12 mb-3 px-1'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                        className='text-center d-flex justify-content-center'
                        >
                        <h1 className='submaintitle'>Health Risk Assessment (HRA) is a questionnaire to evaluate your health risks and Quality of life.</h1>
                </motion.div>
              </div>

              <div className='col-12 mt-4 px-5'>
                <motion.div
                        initial={{ opacity: 0, y: 20  }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                        className='text-center d-flex justify-content-center'
                        >
                       <Link to="/hra-questionnaire" className="btn action-button btn-enrollNow">Take a HRA</Link>
                </motion.div>
              </div>
              
             
          </div>
        </div>
        
    </motion.section>

    </>
  );
}

export default HealthRiskAssessment;
