
import React, { useState, useEffect }  from "react";
import HabitSubMenu from "./HabitSubMenu";
import WaterGraph from "../charts/WaterGraph";
import { useLocation } from 'react-router-dom';
import { water_log_data } from "../services/Services";

const Water =({activity})=>{
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const uid = searchParams.get("goqiiUserId");
    const uuid = searchParams.get('uuid');
    const tab = searchParams.get("tab");
    const [waterData, setWaterData] = useState([]);
    const [typeData, setTypeData] = useState(type);
    let currentDate = new Date();
    let formattedDate, formattedStartDate,formattedEndDate;
    const fetchData = async (userId,sdate,edate) => {
        try {
            console.log("sdate",sdate);
            console.log("edate",edate);
            const response = await water_log_data(userId,sdate,edate,type);
            console.log("response water data", response.data.data);
            setWaterData(response.data.data);
            setTypeData(type);

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
            if(type === 'daily'){
                const previousDate = new Date(currentDate);
                previousDate.setDate(previousDate.getDate());
                formattedStartDate = previousDate.toISOString().slice(0, 10);
                formattedEndDate = currentDate.toISOString().split('T')[0];
                fetchData({uid},{formattedStartDate},{formattedEndDate});

            }else if(type === 'weekly'){
                const startDate = new Date(currentDate);
                startDate.setDate(startDate.getDate() - startDate.getDay()+1);
                const endDate = new Date(currentDate);
                endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
                formattedStartDate = startDate.toISOString().slice(0, 10);
                formattedEndDate = endDate.toISOString().slice(0, 10);
                fetchData({uid},{formattedStartDate},{formattedEndDate});

            }else if(type === 'monthly'){
                const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
                const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
                const formattedStartDate = startDate.toISOString().split('T')[0];
                const formattedEndDate = endDate.toISOString().split('T')[0];
                fetchData({uid},{formattedStartDate},{formattedEndDate}); 
            }else if(type === 'yearly'){
                const startDate = new Date(currentDate.getFullYear(), 0, 2);
                const endDate = new Date(currentDate.getFullYear(), 12, 1);
                formattedStartDate = startDate.toISOString().split('T')[0];
                formattedEndDate = endDate.toISOString().split('T')[0];
                fetchData({uid},{formattedStartDate},{formattedEndDate}); 
            }else{
                const previousDate = new Date(currentDate);
                previousDate.setDate(previousDate.getDate() - 1);
                formattedStartDate = previousDate.toISOString().slice(0, 10);
                formattedEndDate = currentDate.toISOString().split('T')[0];
                fetchData({uid},{formattedStartDate},{formattedEndDate});
            }
      },[type]);
      
    return(
        <>
         <HabitSubMenu activity={activity} uid={uid} startDate= {formattedDate} endDate={formattedDate} tab={tab} uuid ={uuid}/>
            <div className="container contentbox">
                <div className="row">
                    <div className="col-12 px-0">
                        {waterData ? (
                            <WaterGraph waterData={waterData} type={typeData} />
                        ) : (
                            <div className="error-message">
                                <p>No records found. User has not synced data</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Water
